import { createStore } from "redux";
import initialState from "./initialState";
import appReducers from "./reducers";



const store = createStore(
    appReducers,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
