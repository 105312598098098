import database from "../data";
import { loadFromLocalStorage } from "../loacl_storage";

let storage = loadFromLocalStorage();


const initialState = {
    navbar: {
        isNavbarOpen: false,
    },
    theme: storage ? storage.theme : {
        isDarkTheme: true,
        nameColorTheme: "orange",
        isColorPanleOpen: false
    },
    database: database
};

export default initialState;