import React from 'react';
import { motion } from "framer-motion";
import { routerAnimation } from '../../constant/routers';
import { connect } from 'react-redux';
import { getColorThemeByName, getTheme } from '../../helper';
import 'react-circular-progressbar/dist/styles.css';
import ServiceSVG from '../../components/svgs/ServiceSVG';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import Carousel from 'react-bootstrap/Carousel';


const Portfolio = (Props) => {
    let colNumber = 2;
    let cardStyle = { backgroundColor: Props.theme.lightBackColor, height: "100%" };
    let cardTitleStyle = { color: Props.colorTheme.activeColor }
    let cardTextStyle = { textAlign: "justify", color: Props.isDarkTheme ? "white" : "#302e4d" }
    return (
        <>
            <Helmet>
                <title>
                    Portfolio
                </title>
            </Helmet>
            < motion.div {...routerAnimation} className='row mt-4 ms-2 me-2' >
                <div>
                    <div className='row'>
                        <div className='col-12 col-md-9'>
                            <h1>Portfolio</h1>
                            <div style={{ width: "10%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>
                            <div className='mt-2' style={{ width: "7%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>


                            <div className='mt-5'>
                                <h2>Showcase of Creative Chronicles</h2>
                            </div>

                            <p className='mt-2 text-jus ' style={{ textAlign: "justify" }}>
                                Welcome to my creative universe, a curated collection that transcends mediums and captures the essence of my artistic journey. "Showcase of Creative Chronicles" invites you to embark on a visual odyssey, where every stroke, pixel, and design tells a unique story.
                            </p>

                        </div>
                        <div className='col-12 col-md-3'>
                            <ServiceSVG />
                        </div>
                    </div>

                    <h3 className='mb-3'>Websites Portfolio</h3>
                    <Row xs={1} md={colNumber} className="g-5 mb-4">
                        {Props.database.portfolio.types.web.map((service, idx) => (
                            <Col>
                                <Card style={cardStyle}>
                                    <Carousel>
                                        {service.images.map((img, idx) => (
                                            <Carousel.Item >
                                                <Card.Img variant="top" src={img} style={{ width: '100%', height: '500px', objectFit: 'contain' }} />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <Card.Body>
                                        <Card.Title style={cardTitleStyle}>{service.name}</Card.Title>
                                        <Card.Text style={cardTextStyle}>
                                            {service.description}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            service.links.map((link, idx) => (
                                                idx > 0 ? (
                                                    <a className="btn ms-2" href={link} rel="noreferrer" target="_blank" style={{ background: Props.colorTheme.activeColor, color: "white" }}>To Project</a>
                                                ) : (
                                                    <a className="btn" href={link} rel="noreferrer" target="_blank" style={{ background: Props.colorTheme.activeColor, color: "white" }}>To Project</a>
                                                )
                                            ))
                                        }
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <h3 className='mb-3'>Mobile Apps Portfolio</h3>
                    <Row xs={1} md={colNumber} className="g-5 mb-4">
                        {Props.database.portfolio.types.mobile.map((service, idx) => (
                            <Col>
                                <Card style={cardStyle}>
                                    <Carousel>
                                        {service.images.map((img, idx) => (
                                            <Carousel.Item >
                                                <Card.Img variant="top" src={img} style={{ width: '100%', height: '500px', objectFit: 'contain' }} />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <Card.Body>
                                        <Card.Title style={cardTitleStyle}>{service.name}</Card.Title>
                                        <Card.Text style={cardTextStyle}>
                                            {service.description}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            service.links.map((link, idx) => (
                                                idx > 0 ? (
                                                    <a className="btn ms-2" href={link} rel="noreferrer" target="_blank" style={{ background: Props.colorTheme.activeColor, color: "white" }}>To Project</a>
                                                ) : (
                                                    <a className="btn" href={link} rel="noreferrer" target="_blank" style={{ background: Props.colorTheme.activeColor, color: "white" }}>To Project</a>
                                                )
                                            ))
                                        }
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <h3 className='mb-3'>Design Portfolio</h3>
                    <Row xs={1} md={colNumber} className="g-5 mb-4">
                        {Props.database.portfolio.types.design.map((service, idx) => (
                            <Col>
                                <Card style={cardStyle}>
                                    <Carousel>
                                        {service.images.map((img, idx) => (
                                            <Carousel.Item >
                                                <Card.Img variant="top" src={img} style={{ width: '100%', height: '500px', objectFit: 'contain' }} />
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <Card.Body>
                                        <Card.Title style={cardTitleStyle}>{service.name}</Card.Title>
                                        <Card.Text style={cardTextStyle}>
                                            {service.description}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        {
                                            service.links.map((link, idx) => (
                                                idx > 0 ? (
                                                    <a className="btn ms-2" href={link} rel="noreferrer" target="_blank" style={{ background: Props.colorTheme.activeColor, color: "white" }}>To Project</a>
                                                ) : (
                                                    <a className="btn" href={link} rel="noreferrer" target="_blank" style={{ background: Props.colorTheme.activeColor, color: "white" }}>To Project</a>
                                                )
                                            ))
                                        }
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                </div>
            </motion.div >
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        database: state.database,
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}
export default connect(mapStateToProps)(Portfolio);

