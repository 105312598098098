import React from 'react';
import { connect } from 'react-redux';
import { getColorThemeByName, getTheme } from '../../helper';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import CustomIcon from '../../components/CustomIcon/CustomIcon';
import ContactForm from '../../components/ContactForm/ContactForm';
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import 'react-circular-progressbar/dist/styles.css';
import { faContactCard } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';


const Contact = (Props) => {
    let cardStyle = { backgroundColor: Props.theme.lightBackColor, height: "100%", textAlign: "center" };
    let cardTextStyle = {
        color: Props.isDarkTheme ? "white" : "#302e4d"
    }
    return (
        <>
            <Helmet>
                <title>
                    Contact
                </title>
            </Helmet>
            <div className='row mt-4 ms-2 me-2'>
                <div>
                    <h1>Contact Me</h1>
                    <div style={{ width: "10%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>
                    <div className='mt-2' style={{ width: "7%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>
                </div>


                <div className='row'>
                    <div className='col-12 mt-5 text-center'>
                        <h3 style={{ color: Props.colorTheme.activeColor }}>Do You Have Any Questions?</h3>
                        <h4 className='mt-4'>I´m At Your Services</h4>
                    </div>
                </div>

                <Row xs={1} md={1} className="g-5 mb-4">
                    <a href='https://www.instagram.com/22itsghaith/' rel="noreferrer" color='white' target="_blank">
                        <Col>

                            <Card style={cardStyle}>
                                <Card.Body>
                                    <CustomIcon style={{ color: Props.colorTheme.activeColor, fontSize: 30 }}
                                        icon={faInstagram} />
                                    <Card.Text style={cardTextStyle}>@22itsGhaith</Card.Text>
                                </Card.Body>
                            </Card>


                        </Col>
                    </a>
                    <a href='mailto:business@its-ghaith.com?subject=Contact for service requests' color='white'>
                        <Col>
                            <Card style={cardStyle}>
                                <Card.Body>
                                    <CustomIcon style={{ color: Props.colorTheme.activeColor, fontSize: 30 }}
                                        icon={faEnvelope} />
                                    <Card.Text style={cardTextStyle}>
                                        business@its-ghaith.com
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </a>

                    <Col>
                        <Card style={cardStyle}>
                            <Card.Body>
                                <CustomIcon style={{ color: Props.colorTheme.activeColor, fontSize: 30 }} icon={faContactCard} />
                                <h4 className='text-center' style={cardTextStyle}>Or use our contact form</h4>
                                <ContactForm></ContactForm>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        database: state.database,
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}

export default connect(mapStateToProps)(Contact);
