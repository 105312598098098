import React from 'react';
import { connect } from 'react-redux';
import { getColorThemeByName } from '../../helper';


const ActiveText = (Props) => {
  return (
    <span className='d-inline-block' style={{ ...Props.style, color: Props.colorTheme.activeColor }}>
      {Props.children}
    </span>
  )
};


const mapStateToProps = (state) => {
  return {
    nameColorTheme: state.theme.nameColorTheme,
    colorTheme: getColorThemeByName(state.theme.nameColorTheme),
  }
}

export default connect(mapStateToProps)(ActiveText);

