import React from 'react';
import { connect } from 'react-redux';
import ActiveText from '../../components/ActiveText/ActiveText';
import { getColorThemeByName, getTheme } from '../../helper';
import avatar from "../../assets/images/website/avatar.jpg";
import CodingBro from '../../components/svgs/CodingBro';
import { motion } from "framer-motion";
import { routerAnimation } from '../../constant/routers';
import { Helmet } from 'react-helmet-async';
import Typewriter from 'typewriter-effect';


const Home = (Props) => {
    return (
        <>
            <Helmet>
                <title>
                    Home
                </title>
            </Helmet>
            <motion.div className='row align-items-center h-100'
                {...routerAnimation}
            >
                <div className='col-md-7'>
                    <div className='ms-3'>
                        <h1 className='mb-5'>
                            {"Hello, my name is "}
                            <ActiveText style={{ fontFamily: 'Lobster, cursive', }}>
                                {Props.database.personal.firstName} {Props.database.personal.lastName}
                            </ActiveText>
                        </h1>
                        <h1 className='mb-3'>
                            {"Professional at "}

                            {/* cursorColor={Props.theme.normalTextColor}
                            multiText={Props.database.career.name}
                            multiTextLoop={true}
                            typeSpeed={30} */}

                            <ActiveText style={{ fontFamily: 'Lobster, cursive' }}>
                                <Typewriter
                                    options={{
                                        strings: Props.database.career.name,
                                        autoStart: true,
                                        loop: true,
                                        delay: 30,
                                        deleteSpeed: 30,
                                    }}
                                />
                            </ActiveText>
                        </h1>

                        <h4>
                            {Props.database.career.description}
                        </h4>
                    </div>
                </div>
                <div className='col-md-5 text-center mt-md-0 mb-md-0 mt-5 mb-5'>
                    <img style={{
                        padding: "7px", borderRadius: "50%",
                        border: `7px solid ${Props.colorTheme.activeColor}`,
                        maxWidth: "70%"
                    }}
                        width="100%" src={avatar} alt='Avatar' />
                    <div className='d-none d-md-block'>
                        <div>
                            <CodingBro />
                        </div>
                    </div>

                    <div className='d-block d-md-none'>
                        <CodingBro />
                    </div>
                </div>
            </motion.div >

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        database: state.database,
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}

export default connect(mapStateToProps)(Home);
