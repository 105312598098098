import themes from "./constant/themes";

export const getTheme = (isDark) => {
    let theme = isDark ? themes.dark : themes.white
    return {
        ...theme,
    }
}

export const getColorThemeByName = (nameColorTheme) => {
    return themes.appColorsTheme[nameColorTheme];
}

export const checkIsDark = () => {
    return true
}
