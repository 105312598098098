import React from 'react';
import { connect } from 'react-redux';
import Style from 'style-it';
import { getColorThemeByName, getTheme } from '../../helper';


const SkillsList = (Props) => {

  const { list = [] } = Props
  const listAsIl = [];

  list.forEach((element, index) => {
    listAsIl.push(
      <li key={index} className="timeline-item rounded ms-3 p-4 shadow">
        <h4 className='mb-4'>
          <i style={{ color: Props.colorTheme.activeColor }} className={element.icon}></i>
          &nbsp;&nbsp;
          {element.name}
        </h4>
        {
          element.level ? <p className="text-small font-weight-light">
            {element.level}
          </p> : null
        }
      </li>)
  });

  return (
    Style.it(
      `
          ul.timeline {
              list-style-type: none;
              position: relative;
              padding-left: 1.5rem;
          }

          /* Timeline vertical line */
          ul.timeline:before {
              content: ' ';
              background: ${Props.colorTheme.activeColor};
              display: inline-block;
              position: absolute;
              left: 16px;
              width: 4px;
              height: 100%;
              z-index: 400;
              border-radius: 1rem;
          }

          li.timeline-item {
              margin: 20px 0;
              background: ${Props.isDarkTheme ? Props.theme.lightBackColor : "#fdf8ff"};
          }

          /* Timeline item arrow */
          .timeline-arrow {
              border-top: 0.5rem solid transparent;
              border-right: 0.5rem solid ${Props.colorTheme.activeColor};
              border-bottom: 0.5rem solid transparent;
              display: block;
              position: absolute;
              left: 2rem;
          }

          /* Timeline item circle marker */
          li.timeline-item::before {
              content: ' ';
              background: ${Props.theme.normalTextColor};
              display: inline-block;
              position: absolute;
              border-radius: 50%;
              left: 11px;
              width: 14px;
              height: 14px;
              z-index: 400;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
          }
  `,
      <ul className="timeline">
        {listAsIl}
      </ul>
    )
  )
};

const mapStateToProps = (state) => {
  return {
    isDarkTheme: state.theme.isDarkTheme,
    theme: getTheme(state.theme.isDarkTheme),
    nameColorTheme: state.theme.nameColorTheme,
    colorTheme: getColorThemeByName(state.theme.nameColorTheme)
  }
}

export default connect(mapStateToProps)(SkillsList);
