import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import store from "../reduxapp/store";
import { HelmetProvider } from "react-helmet-async";


const ProviderApp = () => {
    return (
        <Provider store={store}>
            <BrowserRouter >
                <div>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </div>
            </BrowserRouter>
        </Provider>
    );
}

export default ProviderApp;
// DOM element
if (document.getElementById('providerApp')) {
    ReactDOM.render(<ProviderApp />, document.getElementById('providerApp'));
}
