import { faBriefcase, faComment, faHouseChimney, faList, faUser } from "@fortawesome/free-solid-svg-icons";
import Home from "../pages/Home";
import About from "../pages/About";
import React from "react";
import { Route, Routes } from 'react-router-dom';
import Services from "../pages/Services";
import Portfolio from "../pages/Portfolio";
import Contact from "../pages/Contact";


let routesData = [
    {
        href: '/',
        icon: faHouseChimney,
        name: "Home",
        component: <Home />
    },
    {
        href: 'about',
        icon: faUser,
        name: "About",
        component: <About />
    },
    {
        href: 'services',
        icon: faList,
        name: "Services",
        component: <Services />
    },
    {
        href: 'portfolio',
        icon: faBriefcase,
        name: "Portfolio",
        component: <Portfolio />
    },
    {
        href: 'contact',
        icon: faComment,
        name: "Contact",
        component: <Contact />
    },
];

const routerAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
}

let links = [];
let routesArray = [];

routesData.map(function (element, index) {
    links.push(element);

    let route = <Route key={index} path={element.href} element={element.component} />
    routesArray.push(route)
});

let routes = <Routes>
    {routesArray}
</Routes>


export { links, routesArray, routes, routerAnimation };
