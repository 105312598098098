import { saveToLocalStorage } from "../../loacl_storage";
import { CHANGE_COLOR_THEME, TOGGLE_COLOR_PANALE, TOGGLE_DARK_THEME } from "../actions/types";
import initialState from "../initialState";

export default function themeReducer(state, action) {
    let newState; // Declare newState here
    let temp;   // Declare temp here

    switch (action.type) {
        case TOGGLE_DARK_THEME:
            const isDarkTheme = !state.isDarkTheme;
            newState = {
                theme: {
                    ...state,
                    isDarkTheme
                }
            };

            temp = newState.theme.isColorPanleOpen;
            delete newState.theme.isColorPanleOpen;
            saveToLocalStorage(newState);
            newState.theme.isColorPanleOpen = temp;

            return newState.theme;

        case CHANGE_COLOR_THEME:
            newState = {
                theme: {
                    ...state,
                    nameColorTheme: action.nameColorTheme
                }
            };

            temp = newState.theme.isColorPanleOpen;
            delete newState.theme.isColorPanleOpen;
            saveToLocalStorage(newState);
            newState.theme.isColorPanleOpen = temp;

            return newState.theme;

        case TOGGLE_COLOR_PANALE:
            const isColorPanleOpen = !state.isColorPanleOpen;
            return {
                ...state,
                isColorPanleOpen: isColorPanleOpen
            };

        default:
            return state === undefined ? initialState.theme : state;
    }
}
