import database from "../../data";
import { GET_DATA_FROM_DATABASE } from "../actions/types";
import initialState from "../initialState";


export default function DatabaseReducer(state, action) {
    switch (action.type) {
        case GET_DATA_FROM_DATABASE:
            return {
                ...state,
                database,
            };

        default:
            return state === undefined ? initialState.database : state
    }
}