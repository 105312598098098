import React from 'react';
import { motion } from "framer-motion";
import { routerAnimation } from '../../constant/routers';
import { connect } from 'react-redux';
import { getColorThemeByName, getTheme } from '../../helper';
import InfoRow from '../../components/InfoRow/InfoRow';
import 'react-circular-progressbar/dist/styles.css';
import TimelineList from '../../components/TimelineList/TimelineList';
import SkillsList from '../../components/SkillsList/SkillsList';
import Tierd from '../../components/svgs/Tierd';
import { Helmet } from 'react-helmet-async';
import { Flat } from '@alptugidin/react-circular-progress-bar'

const About = (Props) => {
    return (
        <>
            <Helmet>
                <title>
                    About
                </title>
            </Helmet>
            < motion.div {...routerAnimation} className='row mt-4 ms-2 me-2' >
                <div>
                    <h1>About Me</h1>
                    <div style={{ width: "10%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>
                    <div className='mt-2' style={{ width: "7%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>
                    <div className='mt-5'>
                        <h2>{Props.database.career.motto}</h2>
                        <p className='mt-2 text-jus ' style={{ textAlign: "justify" }}>
                            {Props.database.career.longDescription}
                        </p>
                    </div>
                    <div className='row align-items-end mt-1'>
                        <div className='col-12 col-md-8'>
                            <InfoRow info1="Degree" value1="Last year of bachelor" info2="Freelance" value2="Availabel" />
                        </div>
                        <div className='col-12 col-md-4 mt-md-0 mt-5'>
                            <div className='row'>
                                <div className='col-6 text-center'>
                                    <h5 className='mb-3'>Backend skills</h5>
                                    <Flat
                                        progress={Props.database.career.backendSkill}
                                        range={{ from: 0, to: 100 }}
                                        sign={{ value: '%', position: 'end' }}
                                        showValue={true}
                                        showMiniCircle={true}
                                        sx={{
                                            strokeColor: Props.colorTheme.activeColor,
                                            bgStrokeColor: Props.theme.lightBackColor,
                                            bgColor: { value: Props.theme.lightBackColor, transparency: '10' },
                                            barWidth: 9,
                                            strokeLinecap: 'round',
                                            shape: 'full',
                                            valueSize: 20,
                                            valueColor: Props.theme.normalTextColor,
                                            textSize: 13,
                                            loadingTime: 1000,
                                            miniCircleColor: 'white',
                                            valueAnimation: true,
                                            intersectionEnabled: true
                                        }}
                                    />
                                </div>
                                <div className='col-6 text-center'>
                                    <h5 className='mb-3'>Frontend skills</h5>
                                    <Flat
                                        progress={Props.database.career.frontendSkill}
                                        range={{ from: 0, to: 100 }}
                                        sign={{ value: '%', position: 'end' }}
                                        showValue={true}
                                        showMiniCircle={true}
                                        sx={{
                                            strokeColor: Props.colorTheme.activeColor,
                                            bgStrokeColor: Props.theme.lightBackColor,
                                            bgColor: { value: Props.theme.lightBackColor, transparency: '10' },
                                            barWidth: 9,
                                            strokeLinecap: 'round',
                                            shape: 'full',
                                            valueSize: 20,
                                            valueColor: Props.theme.normalTextColor,
                                            textSize: 13,
                                            loadingTime: 700,
                                            miniCircleColor: 'white',
                                            valueAnimation: true,
                                            intersectionEnabled: true
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col'>
                            <a
                                className='btn btn-lg'
                                href='https://drive.google.com/uc?export=download&id=1FgJU6gafQeA2-S8WiaJpyKjoThR2gXaJ'
                                download
                                style={
                                    {
                                        backgroundColor: Props.colorTheme.activeColor,
                                        color: Props.theme.normalTextColor,
                                    }}
                            >
                                Download CV
                            </a>
                        </div>
                    </div>

                    <div className='row mt-5'>
                        <div className='col-md-6 col-12'>
                            <div className=''>
                                <h3 className='mb-3'>Education</h3>
                                <TimelineList list={Props.database.education} />
                            </div>
                            <div className=''>
                                <h3 className='mb-3 mt-5'>Careers & Works</h3>
                                <TimelineList list={Props.database.career.careers} />
                            </div>
                            <div style={{ height: "fit-content" }}>
                                <h3 className='mb-3 mt-5'>Programming skills</h3>
                                <div className='row'>

                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.programmingSkills[0]} />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.programmingSkills[1]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex-column col-md-6 col-12 h-100'>
                            <div style={{ height: "fit-content" }}>
                                <h3 className='mb-3 mt-5 mt-md-0'>Study projects</h3>
                                <TimelineList list={Props.database.career.studyProjects} />
                            </div>
                            <div style={{ height: "fit-content" }}>
                                <h3 className='mb-3 mt-5'>Frameworks</h3>
                                <div className='row'>

                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.frameworks[0]} />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.frameworks[1]} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "fit-content" }}>
                                <h3 className='mb-3 mt-5'>Technologies</h3>
                                <div className='row'>

                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.technologien[0]} />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.technologien[1]} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "fit-content" }}>
                                <h3 className='mb-3 mt-5'>Design skills OR Adobe Programs</h3>
                                <div className='row'>

                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.designSkills[0]} />
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <SkillsList list={Props.database.designSkills[1]} />
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: "fit-content" }}>
                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <div>
                                            <Tierd />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </motion.div >
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        database: state.database,
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}
export default connect(mapStateToProps)(About);
