import React from 'react';
import { motion } from "framer-motion";
import { routerAnimation } from '../../constant/routers';
import { connect } from 'react-redux';
import { getColorThemeByName, getTheme } from '../../helper';
import 'react-circular-progressbar/dist/styles.css';
import ServiceSVG from '../../components/svgs/ServiceSVG';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';


const Services = (Props) => {
    let colNumber = 2;
    let cardStyle = { backgroundColor: Props.theme.lightBackColor, height: "100%" };
    let cardTitleStyle = { color: Props.colorTheme.activeColor }
    let cardTextStyle = { textAlign: "justify", color: Props.isDarkTheme ? "white" : "#302e4d" }
    return (
        <>
            <Helmet>
                <title>
                    Services
                </title>
            </Helmet>
            < motion.div {...routerAnimation} className='row mt-4 ms-2 me-2' >
                <div>
                    <div className='row'>
                        <div className='col-12 col-md-9'>
                            <h1>Services</h1>
                            <div style={{ width: "10%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>
                            <div className='mt-2' style={{ width: "7%", height: "0%", border: `1px solid ${Props.colorTheme.activeColor}` }}></div>

                            <div className='mt-5'>
                                <h2>{Props.database.services.motto}</h2>
                            </div>

                            <p className='mt-2 text-jus ' style={{ textAlign: "justify" }}>
                                {Props.database.services.longDescription}
                            </p>

                        </div>
                        <div className='col-12 col-md-3'>
                            <ServiceSVG />
                        </div>
                    </div>

                    <h3 className='mb-3'>Design services</h3>
                    <Row xs={1} md={colNumber} className="g-5 mb-4">
                        {Props.database.services.servicesList.design.map((service, idx) => (
                            <Col>
                                <Card style={cardStyle}>
                                    <Card.Img variant="top" src={Props.isDarkTheme ? service.imgDark : service.imgLight} />
                                    <Card.Body>
                                        <Card.Title style={cardTitleStyle}>{service.name}</Card.Title>
                                        <Card.Text style={cardTextStyle}>
                                            {service.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <h3 className='mb-3'>Web services</h3>
                    <Row xs={1} md={colNumber} className="g-5 mb-4">
                        {Props.database.services.servicesList.web.map((service, idx) => (
                            <Col>
                                <Card style={cardStyle}>
                                    <Card.Img variant="top" src={Props.isDarkTheme ? service.imgDark : service.imgLight} />
                                    <Card.Body>
                                        <Card.Title style={cardTitleStyle}>{service.name}</Card.Title>
                                        <Card.Text style={cardTextStyle}>
                                            {service.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    <h3 className='mb-3'>Mobile app services</h3>
                    <Row xs={1} md={colNumber} className="g-5 mb-4">
                        {Props.database.services.servicesList.mobile.map((service, idx) => (
                            <Col>
                                <Card style={cardStyle}>
                                    <Card.Img variant="top" src={Props.isDarkTheme ? service.imgDark : service.imgLight} />
                                    <Card.Body>
                                        <Card.Title style={cardTitleStyle}>{service.name}</Card.Title>
                                        <Card.Text style={cardTextStyle}>
                                            {service.description}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                </div>
            </motion.div >
        </>

    )
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        database: state.database,
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}
export default connect(mapStateToProps)(Services);