import { TOGGLE_NAVBAR_OPEN } from "../actions/types";
import initialState from "../initialState";


export default function NavBarReducer(state, action) {
    switch (action.type) {
        case TOGGLE_NAVBAR_OPEN:
            return {
                ...state,
                isNavbarOpen: !state.isNavbarOpen
            };

        default:
            return state === undefined ? initialState.navbar : state
    }

}