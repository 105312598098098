import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const themes = {
    white: {
        name: "white",
        darkBackColor: '#fdf9ff',
        lightBackColor: "#e8dfec",
        borderColor: "#8484934F",
        normalTextColor: "#302e4d",
        opTextColor: "#302e4d",
        mainColor: "#302e4d",
        icon: faMoon,
        animation: {
            animate: {},
            transition: {}
        }
    },
    dark: {
        name: "dark",
        darkBackColor: '#282626',
        lightBackColor: "#1E1E1E",
        borderColor: "#8484934F",
        normalTextColor: "#fff",
        opTextColor: "#fff",
        mainColor: "#fff",
        icon: faSun,
        animation: {
            animate: { rotate: 360 },
            transition: { repeat: Infinity, duration: 2 }
        }
    },

    appColorsTheme: {
        turquoise: {
            nameColorTheme: "turquoise",
            activeColor: '#00bcd4',
        },
        pink: {
            nameColorTheme: "pink",
            activeColor: '#FF57B7'
        },
        orange: {
            nameColorTheme: "orange",
            activeColor: '#ff8400'
        },
        red: {
            nameColorTheme: "red",
            activeColor: '#ff3f40'
        }
    }

}

export default themes;
