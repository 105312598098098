import React from 'react';
import { getColorThemeByName, getTheme } from "../../helper";
import { connect } from "react-redux";


const ServersSVG = (Props) => {
    const { width = "100%" } = Props
    return (
        <div style={{ width: "fit-content", margin: "auto" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width={width}>
                <rect x="71.11" y="341.52" width="26.48" height="11.19" fill="#ebebeb"></rect>
                <path d="M82.82,366.82l-13.12.28-6.66.11c-2.23.05-4.47,0-6.7.05H55.9v-.45l-.06-11.19v-.5h.5l13.24.06c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.78-.13,5.65Zm0,0-.14-5.66c-.05-1.86-.11-3.7-.13-5.53l.27.27c-4.41.05-8.83.16-13.24.16l-13.24.07.5-.5-.05,11.19-.45-.45c2.18,0,4.36,0,6.55.06l6.58.11Z" fill="#ebebeb"></path>
                <rect x="284.75" y="143.07" width="26.48" height="11.19" fill="#ebebeb"></rect>
                <path d="M296.46,168.37l-13.12.28-6.67.11c-2.22.05-4.46,0-6.69.06h-.44v-.45l0-11.19v-.5h.5l13.24.07c4.41,0,8.83.11,13.24.16h.28v.27c0,1.9-.08,3.79-.13,5.66Zm0,0-.14-5.65c-.05-1.86-.11-3.71-.13-5.54l.27.28c-4.41,0-8.83.15-13.24.16l-13.24.06.5-.5-.05,11.19-.45-.44c2.18,0,4.36,0,6.55,0l6.57.11Z" fill="#ebebeb"></path>
                <path d="M281.75,154.26l-13.13.28-6.66.11c-2.22,0-4.46,0-6.69.06h-.45v-.45l0-11.19v-.5h.51l13.24.07c4.41,0,8.82.11,13.24.16H282v.27c0,1.9-.09,3.79-.13,5.66Zm0,0-.15-5.65c0-1.86-.11-3.71-.13-5.54l.28.28c-4.42.05-8.83.15-13.24.16l-13.24.06.5-.5-.06,11.19-.44-.44c2.18,0,4.35,0,6.54.05l6.58.11Z" fill="#ebebeb"></path>
                <rect x="417.18" y="355.63" width="26.48" height="11.19" fill="#ebebeb"></rect>
                <rect x="158.28" y="257.77" width="26.48" height="11.19" fill="#ebebeb"></rect>
                <path d="M428.89,83.14l-13.13.28-6.66.11c-2.22,0-4.46,0-6.69,0H402v-.45l0-11.19v-.5h.51l13.24.06c4.41,0,8.82.11,13.24.17h.27V72c0,1.9-.09,3.79-.13,5.65Zm0,0-.14-5.66c-.05-1.86-.12-3.7-.14-5.53l.28.27c-4.42,0-8.83.16-13.24.17l-13.24.06.5-.5-.06,11.19-.44-.45c2.18,0,4.35,0,6.54.06l6.58.11Z" fill="#ebebeb"></path>
                <path d="M443.66,97.25l-13.13.28-6.66.11c-2.22,0-4.46,0-6.69,0h-.45v-.45l-.05-11.19v-.5h.5l13.24.06c4.41,0,8.83.11,13.24.17h.27v.27c0,1.9-.08,3.79-.13,5.65Zm0,0-.14-5.66c0-1.86-.12-3.7-.13-5.53l.27.27c-4.41,0-8.83.16-13.24.16l-13.24.07.5-.5-.06,11.19-.44-.45c2.18,0,4.36,0,6.54.06l6.58.11Z" fill="#ebebeb"></path>
                <rect x="61.11" y="71.95" width="26.48" height="11.19" fill="#ebebeb"></rect>
                <path d="M226.28,153.36a14.76,14.76,0,0,1,8.3-2,7.22,7.22,0,0,1,6.23,11.11,15.79,15.79,0,0,1-14.31,8,7.23,7.23,0,0,1-6.24-11.1A14.79,14.79,0,0,1,226.28,153.36Zm6.64,11.83a11.45,11.45,0,0,0,4.52-4.43c.77-1.57.81-2.9.26-3.81s-1.66-1.63-3.42-1.82a12.05,12.05,0,0,0-10.63,6,4.2,4.2,0,0,0-.26,3.89,4.07,4.07,0,0,0,3.42,1.74A11.34,11.34,0,0,0,232.92,165.19Z" fill="#ebebeb"></path>
                <path d="M255.82,217.9a14.72,14.72,0,0,1,1.58,8.39A7.23,7.23,0,0,1,246,232a15.79,15.79,0,0,1-7.33-14.68,7.23,7.23,0,0,1,11.39-5.7A14.83,14.83,0,0,1,255.82,217.9ZM243.69,224a11.35,11.35,0,0,0,4.2,4.73c1.54.84,2.86,1,3.8.44s1.7-1.58,2-3.33a12.1,12.1,0,0,0-5.45-10.9,4.18,4.18,0,0,0-3.87-.45,4.08,4.08,0,0,0-1.91,3.33A11.36,11.36,0,0,0,243.69,224Z" fill="#ebebeb"></path>
                <path d="M209.87,219l9.89-17.68a1.93,1.93,0,0,1,2.61-.71,1.86,1.86,0,0,1,.72,2.57l-8.25,14.74h1.59a1.63,1.63,0,0,1,1,.23,1.92,1.92,0,0,1-.86,3.57l-4.91.12s-.11,0-.15,0-.13,0-.2,0a1.59,1.59,0,0,1-.2-.07c-.05,0-.11,0-.14,0a.58.58,0,0,1-.22-.08l-.09,0,0,0-.21-.12a.27.27,0,0,0-.1-.09c-.1,0-.11-.1-.15-.16a.35.35,0,0,1-.11-.1c-.05-.11-.06-.16-.13-.19a.46.46,0,0,0-.06-.16,1.11,1.11,0,0,1-.07-.16c-.05-.06,0-.14-.06-.18a.79.79,0,0,1,0-.17s0-.14-.05-.19v-.08s0-.07,0-.1,0-.13,0-.19a1.41,1.41,0,0,0,0-.18,1,1,0,0,0,0-.17c.05-.09.09-.15.06-.24Z" fill="#ebebeb"></path>
                <path d="M251.25,200.72l-10.56-17.29a1.92,1.92,0,0,1,.66-2.62,1.87,1.87,0,0,1,2.6.63l8.8,14.42.76-1.39a1.82,1.82,0,0,1,.68-.74,1.86,1.86,0,0,1,1.88,0,1.83,1.83,0,0,1,.81,2.52l-2.3,4.35s-.07.08-.08.12-.05.12-.09.18-.1.1-.16.14-.07.08-.1.1a.45.45,0,0,1-.19.15l-.08.06,0,0-.21.12s-.1,0-.13,0a.3.3,0,0,1-.21.05.22.22,0,0,1-.14.05c-.12,0-.17,0-.23,0a.44.44,0,0,0-.17,0l-.17,0a.49.49,0,0,1-.19,0,.52.52,0,0,1-.16-.07l-.19,0-.06,0s-.07-.05-.09-.07-.11-.06-.14-.12-.11-.09-.15-.11l-.12-.13a.28.28,0,0,0-.19-.16Z" fill="#ebebeb"></path>
                <path d="M66.88,143.2a2.65,2.65,0,0,1,2.78,0l.72.4c.24.14.49.26.74.38a2.64,2.64,0,0,1,1.55,2.3l.11,3.43,11.16-.35-.1-3.42a2.62,2.62,0,0,1,1.4-2.41,15.81,15.81,0,0,0,1.41-.88,2.64,2.64,0,0,1,2.77-.17l3,1.61,5.28-9.84-3-1.62a2.66,2.66,0,0,1-1.38-2.41,15.35,15.35,0,0,0-.06-1.66,2.68,2.68,0,0,1,1.23-2.5l2.92-1.81-5.89-9.49-2.93,1.82a2.68,2.68,0,0,1-2.77,0c-.23-.15-.47-.28-.72-.41s-.48-.26-.73-.37a2.67,2.67,0,0,1-1.56-2.31l-.1-3.43-11.16.34.11,3.42a2.66,2.66,0,0,1-1.4,2.41,14.65,14.65,0,0,0-1.42.88,2.68,2.68,0,0,1-2.78.18l-3-1.62-5.28,9.85,3,1.61a2.66,2.66,0,0,1,1.39,2.41,15.45,15.45,0,0,0,0,1.67,2.65,2.65,0,0,1-1.23,2.5l-2.91,1.8L64,145Zm2.8-17.63A9.15,9.15,0,1,1,73.42,138,9.15,9.15,0,0,1,69.68,125.57Z" fill="#ebebeb"></path>
                <path d="M416.53,61.49a1.85,1.85,0,0,1,.91,1.73c0,.19,0,.38,0,.57s0,.39,0,.58a1.88,1.88,0,0,1-.91,1.73l-2.08,1.2,3.91,6.77,2.08-1.2a1.87,1.87,0,0,1,1.95.07c.33.21.66.41,1,.59a1.85,1.85,0,0,1,1,1.65v2.39h7.82V75.18a1.87,1.87,0,0,1,1-1.65,11.12,11.12,0,0,0,1-.59,1.87,1.87,0,0,1,1.95-.07l2.08,1.2,3.91-6.77-2.09-1.21a1.84,1.84,0,0,1-.9-1.72c0-.19,0-.38,0-.58s0-.38,0-.57a1.85,1.85,0,0,1,.91-1.73l2.08-1.2-3.91-6.77-2.07,1.19a1.85,1.85,0,0,1-1.95-.06c-.33-.21-.67-.41-1-.59a1.87,1.87,0,0,1-1-1.65V50h-7.82v2.4a1.87,1.87,0,0,1-1,1.65,11.12,11.12,0,0,0-1,.59,1.87,1.87,0,0,1-2,.06l-2.07-1.19-3.91,6.77Zm11.81-4.11a6.41,6.41,0,1,1-6.41,6.41A6.42,6.42,0,0,1,428.34,57.38Z" fill="#ebebeb"></path>
                <path d="M362.94,155.51A30.47,30.47,0,1,0,393.41,125,30.48,30.48,0,0,0,362.94,155.51Zm16.24,0a14.24,14.24,0,1,1,14.23,14.22A14.24,14.24,0,0,1,379.18,155.51Z" fill="#ebebeb"></path>
                <polygon points="387.55 126.3 399.27 126.3 398.62 118 388.2 118 387.55 126.3" fill="#ebebeb"></polygon>
                <polygon points="409.86 130.25 418.14 138.53 423.55 132.21 416.18 124.84 409.86 130.25" fill="#ebebeb"></polygon>
                <polygon points="422.84 148.81 422.84 160.53 431.13 159.88 431.13 149.46 422.84 148.81" fill="#ebebeb"></polygon>
                <polygon points="418.89 171.11 410.6 179.4 416.93 184.81 424.3 177.44 418.89 171.11" fill="#ebebeb"></polygon>
                <polygon points="400.32 184.09 388.61 184.09 389.25 192.39 399.68 192.39 400.32 184.09" fill="#ebebeb"></polygon>
                <polygon points="378.02 180.14 369.74 171.86 364.33 178.18 371.7 185.55 378.02 180.14" fill="#ebebeb"></polygon>
                <polygon points="365.04 161.58 365.04 149.86 356.75 150.51 356.75 160.93 365.04 161.58" fill="#ebebeb"></polygon>
                <polygon points="368.99 139.28 377.28 130.99 370.95 125.58 363.58 132.96 368.99 139.28" fill="#ebebeb"></polygon>
                <path d="M109.52,265.19A30.47,30.47,0,1,0,140,234.72,30.47,30.47,0,0,0,109.52,265.19Zm16.23,0A14.24,14.24,0,1,1,140,279.41,14.25,14.25,0,0,1,125.75,265.19Z" fill="#ebebeb"></path>
                <polygon points="134.13 235.98 145.85 235.98 145.2 227.68 134.78 227.68 134.13 235.98" fill="#ebebeb"></polygon>
                <polygon points="156.43 239.93 164.72 248.21 170.13 241.89 162.75 234.52 156.43 239.93" fill="#ebebeb"></polygon>
                <polygon points="169.41 258.49 169.41 270.21 177.71 269.56 177.71 259.14 169.41 258.49" fill="#ebebeb"></polygon>
                <polygon points="165.46 280.79 157.18 289.08 163.5 294.49 170.87 287.12 165.46 280.79" fill="#ebebeb"></polygon>
                <polygon points="146.9 293.77 135.18 293.77 135.83 302.07 146.25 302.07 146.9 293.77" fill="#ebebeb"></polygon>
                <polygon points="124.6 289.82 116.31 281.54 110.9 287.86 118.27 295.23 124.6 289.82" fill="#ebebeb"></polygon>
                <polygon points="111.62 271.26 111.62 259.54 103.32 260.19 103.32 270.61 111.62 271.26" fill="#ebebeb"></polygon>
                <polygon points="115.56 248.96 123.85 240.67 117.53 235.26 110.16 242.63 115.56 248.96" fill="#ebebeb"></polygon>
                <rect x="316.21" y="26.96" width="67.79" height="103.41" transform="translate(271.43 428.77) rotate(-90)" fill="#ebebeb"></rect>
                <rect x="360.34" y="71.56" width="28.65" height="46" transform="translate(280.1 469.23) rotate(-90)" fill="#fff"></rect>
                <rect x="360.34" y="39.78" width="28.65" height="46" transform="translate(311.89 437.44) rotate(-90)" fill="#fff"></rect>
                <rect x="311.21" y="71.56" width="28.65" height="46" transform="translate(230.98 420.1) rotate(-90)" fill="#fff"></rect>
                <rect x="311.21" y="39.78" width="28.65" height="46" transform="translate(262.76 388.31) rotate(-90)" fill="#fff"></rect>
                <polygon points="306.64 80.23 322.86 80.23 302.54 100.55 302.54 84.33 306.64 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="348.54 48.45 348.54 54.55 325.99 77.1 309.77 77.1 338.42 48.45 348.54 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="354.64 48.45 351.67 51.42 351.67 48.45 354.64 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="337.12 108.89 348.54 97.46 348.54 108.89 337.12 108.89" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="365.77 80.23 382 80.23 353.34 108.89 351.67 108.89 351.67 94.33 365.77 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="397.67 48.45 397.67 64.56 385.13 77.1 368.9 77.1 397.55 48.45 397.67 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="330.16 48.45 302.54 76.07 302.54 67.95 322.04 48.45 330.16 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="397.67 84.69 397.67 92.8 381.58 108.89 373.47 108.89 397.67 84.69" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="330.03 108.89 348.54 90.38 348.54 92.4 332.05 108.89 330.03 108.89" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="360.71 80.23 351.67 89.27 351.67 87.25 358.69 80.23 360.71 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="392.49 48.45 363.84 77.1 361.82 77.1 390.47 48.45 392.49 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="396.35 80.23 367.69 108.89 365.67 108.89 394.33 80.23 396.35 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="397.67 76.89 397.67 77.1 397.46 77.1 397.67 76.89" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <rect x="116" y="26.96" width="67.79" height="103.41" transform="translate(71.23 228.57) rotate(-90)" fill="#ebebeb"></rect>
                <rect x="160.14" y="71.56" width="28.65" height="46" transform="translate(79.9 269.02) rotate(-90)" fill="#fff"></rect>
                <rect x="160.14" y="39.78" width="28.65" height="46" transform="translate(111.69 237.24) rotate(-90)" fill="#fff"></rect>
                <rect x="111.01" y="71.56" width="28.65" height="46" transform="translate(30.77 219.89) rotate(-90)" fill="#fff"></rect>
                <rect x="111.01" y="39.78" width="28.65" height="46" transform="translate(62.56 188.11) rotate(-90)" fill="#fff"></rect>
                <polygon points="106.43 80.23 122.65 80.23 102.33 100.55 102.33 84.33 106.43 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="148.33 48.45 148.33 54.55 125.78 77.1 109.56 77.1 138.21 48.45 148.33 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="154.43 48.45 151.46 51.42 151.46 48.45 154.43 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="136.91 108.89 148.33 97.46 148.33 108.89 136.91 108.89" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="165.56 80.23 181.79 80.23 153.13 108.89 151.46 108.89 151.46 94.33 165.56 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="197.46 48.45 197.46 64.56 184.92 77.1 168.69 77.1 197.34 48.45 197.46 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="129.95 48.45 102.33 76.07 102.33 67.95 121.83 48.45 129.95 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="197.46 84.69 197.46 92.8 181.37 108.89 173.26 108.89 197.46 84.69" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="129.82 108.89 148.33 90.38 148.33 92.4 131.84 108.89 129.82 108.89" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="160.5 80.23 151.46 89.27 151.46 87.25 158.48 80.23 160.5 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="192.28 48.45 163.63 77.1 161.61 77.1 190.26 48.45 192.28 48.45" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="196.14 80.23 167.48 108.89 165.46 108.89 194.12 80.23 196.14 80.23" fill="#ebebeb" fillOpacity="0.2"></polygon>
                <polygon points="197.46 76.89 197.46 77.1 197.25 77.1 197.46 76.89" fill="#ebebeb" fillOpacity="0.2"></polygon>

                <polygon points="50.27 451.39 100.2 451.15 150.13 451.06 250 450.9 349.87 451.06 399.8 451.15 449.73 451.39 399.8 451.64 349.87 451.73 250 451.89 150.13 451.73 100.2 451.64 50.27 451.39" fill="#263238"></polygon>

                <path d="M233.37,168.26l-6.53,24.57a2.39,2.39,0,0,1-2.94,1.66,2.33,2.33,0,0,1-1.68-2.89l5.45-20.49-1.93.52a2.14,2.14,0,0,1-1.25,0,2.41,2.41,0,0,1-.07-4.61l5.93-1.68c.06,0,.14,0,.19,0s.15-.06.24-.07.18,0,.26,0a.28.28,0,0,1,.18,0,.73.73,0,0,1,.3,0l.13,0h0l.29.08a.29.29,0,0,0,.16.09.4.4,0,0,1,.22.15.25.25,0,0,1,.16.08c.1.12.13.17.22.19a.48.48,0,0,0,.13.17s.1.12.13.17a.46.46,0,0,1,.12.21.53.53,0,0,1,.09.2l.12.21,0,.1s0,.09,0,.13,0,.15,0,.23,0,.18,0,.23a2,2,0,0,0,0,.22.36.36,0,0,0,0,.32Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M193.89,161.69a18.47,18.47,0,0,1,2.93-10.3,9.07,9.07,0,0,1,16,0,19.78,19.78,0,0,1,0,20.59,9.07,9.07,0,0,1-16,0A18.53,18.53,0,0,1,193.89,161.69Zm17,0a14.27,14.27,0,0,0-2.07-7.67c-1.25-1.81-2.67-2.67-4-2.63s-2.8.82-4.09,2.63a14.27,14.27,0,0,0-2.07,7.67,14.11,14.11,0,0,0,2.07,7.62c1.29,1.85,2.76,2.67,4.09,2.67s2.76-.82,4-2.67A14.11,14.11,0,0,0,210.91,161.69Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M216.62,205.75a18.55,18.55,0,0,1-.54-10.69,9.07,9.07,0,0,1,15.14-5.13,19.79,19.79,0,0,1,6.61,19.5c-.84,3.15-2.87,6-6,7.09s-6.54,0-9.13-2A18.59,18.59,0,0,1,216.62,205.75Zm16.11-5.46a14.19,14.19,0,0,0-4.42-6.6c-1.76-1.32-3.39-1.67-4.63-1.21s-2.4,1.68-3,3.81a15.15,15.15,0,0,0,4.91,14.48c1.82,1.34,3.47,1.65,4.74,1.22s2.34-1.66,2.93-3.82A14.15,14.15,0,0,0,232.73,200.29Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M193.26,183.45l16.49,19.34a2.4,2.4,0,0,1-.3,3.37,2.33,2.33,0,0,1-3.34-.27l-13.75-16.12-.67,1.87a2.17,2.17,0,0,1-.69,1,2.41,2.41,0,0,1-3.83-2.56l2-5.83c0-.06.07-.12.07-.18s.05-.15.08-.24.11-.15.17-.2a.23.23,0,0,1,.11-.14.56.56,0,0,1,.2-.23l.1-.09,0,0,.23-.2a.3.3,0,0,0,.16-.08.44.44,0,0,1,.25-.1.27.27,0,0,1,.16-.08c.15,0,.21,0,.28-.07a.52.52,0,0,0,.21,0l.22,0a.86.86,0,0,1,.24,0,.69.69,0,0,1,.21,0l.24,0,.09,0s.09,0,.12.07.15,0,.2.11.15.1.21.1.15.11.17.14.14.16.27.17Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M221.75,149.39l-13.25-21.7a2.41,2.41,0,0,1,.83-3.28,2.35,2.35,0,0,1,3.26.79l11,18.09,1-1.74a2.06,2.06,0,0,1,.85-.92,2.41,2.41,0,0,1,3.37,3.13l-2.88,5.45c0,.06-.09.1-.1.16s-.07.14-.12.22-.13.13-.2.18-.09.1-.12.12a.91.91,0,0,1-.24.2l-.11.06,0,0-.26.15a.31.31,0,0,0-.17,0,.48.48,0,0,1-.27.07.29.29,0,0,1-.17.05c-.15,0-.21,0-.28,0l-.22,0a1.49,1.49,0,0,1-.21,0,.72.72,0,0,1-.24,0,.75.75,0,0,1-.2-.08l-.24-.06-.08,0s-.08-.05-.1-.09a.55.55,0,0,1-.19-.14c-.08,0-.13-.12-.19-.14s-.12-.12-.14-.16-.11-.18-.24-.21Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M246,226.82l-11,22.92a2.4,2.4,0,0,1-3.2,1.09,2.33,2.33,0,0,1-1.11-3.16l9.16-19.11-2,.14a2.08,2.08,0,0,1-1.23-.21,2.41,2.41,0,0,1,.79-4.54l6.14-.54c.06,0,.13,0,.19,0s.16,0,.25,0,.18,0,.25.07a.27.27,0,0,1,.18,0,.75.75,0,0,1,.29.09l.11.06h0l.27.13a.31.31,0,0,0,.14.12c.12,0,.14.11.2.19s.11,0,.13.11.1.19.18.23a.58.58,0,0,0,.1.19c0,.06.08.14.1.19a.63.63,0,0,1,.08.23.6.6,0,0,1,0,.21l.08.23v.1s0,.1,0,.13a.47.47,0,0,1,0,.24c0,.09,0,.17,0,.23a1.89,1.89,0,0,0-.06.21.39.39,0,0,0,0,.31Z" style={{ fill: Props.colorTheme.activeColor }}></path>



                <polygon points="445.6 438.02 280.67 438.02 276.53 451.39 449.73 451.39 445.6 438.02" fill="#37474f"></polygon>
                <polygon points="291.01 240.93 435.25 240.93 445.6 294.87 280.67 294.87 291.01 240.93" fill="#37474f"></polygon>
                <polygon points="249.06 305.94 280.67 305.94 280.67 348.52 235.61 348.52 249.06 305.94" fill="#dbdbdb"></polygon>
                <polygon points="249.06 259.14 249.06 305.94 206.48 314.91 206.48 259.14 249.06 259.14" fill="#dbdbdb"></polygon>
                <polygon points="235.61 348.52 206.48 314.91 249.06 305.94 235.61 348.52" fill="#dbdbdb"></polygon>
                <polygon points="208.75 317.53 206.48 314.91 249.06 305.94 208.75 317.53" fill="#c7c7c7"></polygon>
                <polygon points="235.61 348.52 232.67 345.12 249.06 305.94 235.61 348.52" fill="#c7c7c7"></polygon>
                <polygon points="264.77 232.6 190.77 232.6 206.48 259.14 249.06 259.14 264.77 232.6" fill="#dbdbdb"></polygon>
                <rect x="190.77" y="229.19" width="74" height="3.42" fill="#c7c7c7"></rect>
                <polygon points="249.06 259.14 206.48 259.14 206.48 263.08 248.95 260.43 249.06 259.14" fill="#c7c7c7"></polygon>
                <path d="M206.48,232.6c2.43,4.4,4.88,8.78,7.26,13.21l7.2,13.24,0,0v.05l0,13.19L221,285.52l-.06,13.19-.13,13.19-.14-13.19-.05-13.19-.06-13.19,0-13.19,0,.1-7.09-13.3C211.12,241.52,208.82,237.05,206.48,232.6Z" fill="#c7c7c7"></path>
                <path d="M239.46,336.34c3.43-.1,6.87-.16,10.3-.2l10.3-.05,10.31.05c3.43,0,6.86.1,10.3.2q-5.16.14-10.3.19l-10.31.06-10.3-.06C246.33,336.49,242.89,336.43,239.46,336.34Z" fill="#c7c7c7"></path>
                <path d="M218.63,267.71a1.27,1.27,0,0,1-1.14,1.26,1.31,1.31,0,0,1-1-.3,1.29,1.29,0,0,1-.43-1,1.27,1.27,0,0,1,.43-.95,1.32,1.32,0,0,1,1-.31A1.28,1.28,0,0,1,218.63,267.71Zm0,0a1,1,0,1,0-1.14,1A1,1,0,0,0,218.63,267.71Z" fill="#c7c7c7"></path>
                <path d="M216.66,256.62a1.26,1.26,0,1,1-1.14-1.26A1.27,1.27,0,0,1,216.66,256.62Zm0,0a1,1,0,1,0-2,0,1,1,0,0,0,.88,1A1,1,0,0,0,216.66,256.62Z" fill="#c7c7c7"></path>
                <path d="M211.5,247a1.28,1.28,0,0,1-1.14,1.26,1.32,1.32,0,0,1-1-.31,1.27,1.27,0,0,1-.43-1,1.25,1.25,0,0,1,1.38-1.26A1.27,1.27,0,0,1,211.5,247Zm0,0a1,1,0,0,0-1.14-1,1,1,0,0,0-.88,1,1,1,0,1,0,2,0Z" fill="#c7c7c7"></path>
                <path d="M206.48,237.39a1.26,1.26,0,0,1-1.13,1.26,1.27,1.27,0,0,1-1-2.21,1.33,1.33,0,0,1,1-.31A1.28,1.28,0,0,1,206.48,237.39Zm0,0a1,1,0,0,0-1.13-1,1,1,0,0,0,0,2A1,1,0,0,0,206.48,237.39Z" fill="#c7c7c7"></path>
                <path d="M218.63,279.59a1.27,1.27,0,0,1-1.14,1.26,1.32,1.32,0,0,1-1-.31,1.27,1.27,0,0,1-.43-1,1.25,1.25,0,0,1,1.38-1.26A1.27,1.27,0,0,1,218.63,279.59Zm0,0a1,1,0,1,0-1.14,1A1,1,0,0,0,218.63,279.59Z" fill="#c7c7c7"></path>
                <path d="M218.63,291.46a1.27,1.27,0,0,1-1.14,1.26,1.31,1.31,0,0,1-1-.3,1.29,1.29,0,0,1-.43-1,1.27,1.27,0,0,1,.43-.95,1.32,1.32,0,0,1,1-.31A1.28,1.28,0,0,1,218.63,291.46Zm0,0a1,1,0,1,0-1.14,1A1,1,0,0,0,218.63,291.46Z" fill="#c7c7c7"></path>
                <path d="M218.63,303.34a1.28,1.28,0,0,1-1.14,1.26,1.32,1.32,0,0,1-1-.31,1.27,1.27,0,0,1-.43-1,1.25,1.25,0,0,1,1.38-1.26A1.27,1.27,0,0,1,218.63,303.34Zm0,0a1,1,0,1,0-1.14,1A1,1,0,0,0,218.63,303.34Z" fill="#c7c7c7"></path>
                <path d="M242,338a1.27,1.27,0,0,1,1.26,1.14,1.31,1.31,0,0,1-.3.95,1.29,1.29,0,0,1-1,.43,1.26,1.26,0,0,1,0-2.52Zm0,0a1,1,0,1,0,1,1.14A1,1,0,0,0,242,338Z" fill="#c7c7c7"></path>
                <path d="M253.86,338a1.26,1.26,0,1,1-1.26,1.14A1.27,1.27,0,0,1,253.86,338Zm0,0a1,1,0,1,0,1,1.14A1,1,0,0,0,253.86,338Z" fill="#c7c7c7"></path>
                <path d="M265.73,338a1.27,1.27,0,0,1,1.26,1.14,1.31,1.31,0,0,1-.3.95,1.29,1.29,0,0,1-1,.43,1.26,1.26,0,0,1,0-2.52Zm0,0a1,1,0,1,0,1,1.14A1,1,0,0,0,265.73,338Z" fill="#c7c7c7"></path>
                <path d="M277.61,338a1.27,1.27,0,0,1,1.26,1.14,1.32,1.32,0,0,1-.31.95,1.27,1.27,0,0,1-.95.43,1.25,1.25,0,0,1-1.26-1.38A1.27,1.27,0,0,1,277.61,338Zm0,0a1,1,0,1,0,1,1.14A1,1,0,0,0,277.61,338Z" fill="#c7c7c7"></path>
                <rect x="291.01" y="179.12" width="144.25" height="61.8" fill="#455a64"></rect>
                <rect x="296.5" y="184.42" width="133.26" height="51.1" fill="#263238"></rect>
                <path d="M303.61,195.79c19.84-.07,39.68,0,59.52-.08s39.69,0,59.53.08c-19.84.07-39.68.05-59.53.08S323.45,195.86,303.61,195.79Z" fill="#455a64"></path>
                <path d="M303.61,202.29c19.84-.07,39.68,0,59.52-.08s39.69,0,59.53.08c-19.84.07-39.68.05-59.53.08S323.45,202.36,303.61,202.29Z" fill="#455a64"></path>
                <path d="M303.61,208.79c19.84-.07,39.68,0,59.52-.08s39.69,0,59.53.08c-19.84.07-39.68.05-59.53.08S323.45,208.86,303.61,208.79Z" fill="#455a64"></path>
                <path d="M303.61,215.29c19.84-.07,39.68,0,59.52-.08s39.69,0,59.53.08c-19.84.07-39.68.05-59.53.08S323.45,215.36,303.61,215.29Z" fill="#455a64"></path>
                <path d="M303.61,221.79c19.84-.06,39.68,0,59.52-.08s39.69,0,59.53.08c-19.84.07-39.68.05-59.53.08S323.45,221.86,303.61,221.79Z" fill="#455a64"></path>
                <path d="M327.58,191.56c.17,6.07.13,12.15.2,18.22s0,12.15-.2,18.23c-.18-6.08-.13-12.15-.21-18.23C327.45,203.71,327.4,197.63,327.58,191.56Z" fill="#455a64"></path>
                <path d="M348.16,191.56c.18,6.07.14,12.15.21,18.22s0,12.15-.21,18.23c-.17-6.08-.13-12.15-.2-18.23S348,197.63,348.16,191.56Z" fill="#455a64"></path>
                <path d="M368.75,191.56c.18,6.07.13,12.15.21,18.22-.08,6.08,0,12.15-.21,18.23-.17-6.08-.13-12.15-.2-18.23S368.58,197.63,368.75,191.56Z" fill="#455a64"></path>
                <path d="M389.34,191.56c.17,6.07.13,12.15.2,18.22s0,12.15-.2,18.23c-.18-6.08-.13-12.15-.2-18.23S389.17,197.63,389.34,191.56Z" fill="#455a64"></path>
                <path d="M409.93,191.56c.17,6.07.13,12.15.2,18.22s0,12.15-.2,18.23c-.18-6.08-.13-12.15-.21-18.23C409.8,203.71,409.75,197.63,409.93,191.56Z" fill="#455a64"></path>
                <path d="M306.61,217.08c3.63,0,5.8-6.61,10.94-6.61s5.69,4.82,10,4.82,4.2-8.46,10-8.46,4.47,3.43,10.56,3.43,3.8-8,10.16-8,3.8,11,10.43,11c4.2,0,5.83-3.49,10.84-3.49,3.32,0,5.27,1.75,9.61,1.75s5.29-6.71,10.71-6.71,6,4,10,4,5.42-6.5,12.73-6.5V228h-116Z" style={{ fill: Props.colorTheme.activeColor }} fillOpacity="0.5"></path>
                <path d="M306.61,217.4v-.64c1.1,0,2.61-1.54,3.72-2.67,1.9-1.93,3.87-3.93,7.22-3.93a8.34,8.34,0,0,1,6.24,2.75c1.11,1,2.25,2.06,3.79,2.06,1.35,0,2.53-2,3.38-3.51,1.41-2.44,2.86-5,6.64-5a8.3,8.3,0,0,1,5.86,1.86,6.6,6.6,0,0,0,4.7,1.57c2.35,0,3.26-1.86,4.15-3.66,1-2.12,2.12-4.31,6-4.31s4.85,2.76,5.89,5.68c.85,2.36,1.9,5.31,4.54,5.31a8.62,8.62,0,0,0,4.24-1.5,12.41,12.41,0,0,1,6.6-2,15,15,0,0,1,4.63.85,16,16,0,0,0,5,.89c1.5,0,2.88-1.59,4-2.88,1.62-1.88,3.29-3.83,6.73-3.83a9.63,9.63,0,0,1,6.57,2.39,5.81,5.81,0,0,0,3.45,1.58c1.14,0,2.44-1.23,3.59-2.32,2-1.86,4.39-4.18,9.14-4.18v.64c-3.66,0-5.77,2-7.64,3.78-1.59,1.52-2.85,2.72-5.09,2.72a7.34,7.34,0,0,1-4.9-1.93,7.82,7.82,0,0,0-5.12-2c-2.27,0-3.82,1.8-5.19,3.4-1.53,1.77-2.84,3.31-5.52,3.31a19,19,0,0,1-6-1,12.31,12.31,0,0,0-3.63-.72,10.19,10.19,0,0,0-5.26,1.73,10.64,10.64,0,0,1-5.58,1.76c-4.06,0-5.07-2.84-6.15-5.85-.82-2.29-1.84-5.13-4.28-5.13s-3.53,2-4.41,3.79c-1.06,2.15-2.06,4.18-5.75,4.18s-4.94-1-6.14-1.92a6.21,6.21,0,0,0-4.42-1.51c-2.48,0-3.9,2.46-5.05,4.45-1.29,2.24-2.32,4-5,4s-3.91-1.19-5.29-2.45-2.59-2.37-4.74-2.37-4.15,2-5.7,3.53C310.16,216,308.83,217.4,306.61,217.4Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M306.61,192c.13,6,.24,12,.25,18l.13,18-.38-.15,58,0,29,0,29,.08-29,.08-29,0-58,0h-.39V228l.12-18C306.36,204,306.47,198,306.61,192Z" fill="#455a64"></path>
                <polygon points="319.53 283.09 317.71 283.09 321.45 251.55 323.11 251.55 319.53 283.09" fill="#263238"></polygon>
                <polygon points="325.61 255.5 318.11 255.5 318.3 253.98 325.77 253.98 325.61 255.5" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="330.48 283.09 328.67 283.09 331.51 251.55 333.17 251.55 330.48 283.09" fill="#263238"></polygon>
                <polygon points="334.23 275.7 326.3 275.7 326.45 274.17 334.34 274.17 334.23 275.7" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="341.44 283.09 339.63 283.09 341.56 251.55 343.23 251.55 341.44 283.09" fill="#263238"></polygon>
                <polygon points="345.77 259.25 338.19 259.25 338.29 257.72 345.84 257.72 345.77 259.25" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="352.4 283.09 350.58 283.09 351.62 251.55 353.28 251.55 352.4 283.09" fill="#263238"></polygon>
                <polygon points="355.96 263.62 348.28 263.62 348.35 262.1 355.99 262.1 355.96 263.62" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="302.35 254.81 296 254.81 296.61 251.55 302.91 251.55 302.35 254.81" fill="#dbdbdb"></polygon>
                <polygon points="301.38 260.47 294.93 260.47 295.54 257.2 301.94 257.2 301.38 260.47" fill="#a6a6a6"></polygon>
                <polygon points="300.41 266.12 293.86 266.12 294.48 262.86 300.97 262.86 300.41 266.12" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="299.44 271.78 292.79 271.78 293.41 268.51 300 268.51 299.44 271.78" fill="#dbdbdb"></polygon>
                <polygon points="298.48 277.43 291.72 277.43 292.34 274.17 299.04 274.17 298.48 277.43" fill="#a6a6a6"></polygon>
                <polygon points="297.51 283.09 290.65 283.09 291.27 279.82 298.07 279.82 297.51 283.09" fill="#dbdbdb"></polygon>
                <polygon points="312.07 254.81 305.72 254.81 306.24 251.55 312.54 251.55 312.07 254.81" fill="#dbdbdb"></polygon>
                <polygon points="311.26 260.47 304.8 260.47 305.33 257.2 311.73 257.2 311.26 260.47" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="310.44 266.12 303.89 266.12 304.42 262.86 310.91 262.86 310.44 266.12" fill="#a6a6a6"></polygon>
                <polygon points="309.63 271.78 302.98 271.78 303.5 268.51 310.1 268.51 309.63 271.78" fill="#dbdbdb"></polygon>
                <polygon points="308.82 277.43 302.06 277.43 302.59 274.17 309.29 274.17 308.82 277.43" fill="#a6a6a6"></polygon>
                <polygon points="308 283.09 301.15 283.09 301.68 279.82 308.47 279.82 308 283.09" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="408.96 256.44 404.01 256.44 403.08 251.55 407.96 251.55 408.96 256.44" fill="#a6a6a6"></polygon>
                <polygon points="410.33 263.1 405.28 263.1 404.35 258.21 409.33 258.21 410.33 263.1" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="411.69 269.76 406.55 269.76 405.62 264.88 410.69 264.88 411.69 269.76" fill="#dbdbdb"></polygon>
                <polygon points="413.05 276.42 407.82 276.42 406.89 271.54 412.05 271.54 413.05 276.42" fill="#263238"></polygon>
                <polygon points="414.41 283.09 409.09 283.09 408.16 278.2 413.41 278.2 414.41 283.09" fill="#a6a6a6"></polygon>
                <polygon points="415.54 256.44 410.58 256.44 409.56 251.55 414.45 251.55 415.54 256.44" fill="#dbdbdb"></polygon>
                <polygon points="417.02 263.1 411.98 263.1 410.96 258.21 415.94 258.21 417.02 263.1" fill="#263238"></polygon>
                <polygon points="418.51 269.76 413.37 269.76 412.35 264.88 417.42 264.88 418.51 269.76" fill="#a6a6a6"></polygon>
                <polygon points="419.99 276.42 414.76 276.42 413.74 271.54 418.9 271.54 419.99 276.42" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="421.48 283.09 416.15 283.09 415.13 278.2 420.39 278.2 421.48 283.09" fill="#dbdbdb"></polygon>
                <polygon points="422.11 256.44 417.16 256.44 416.05 251.55 420.94 251.55 422.11 256.44" fill="#a6a6a6"></polygon>
                <polygon points="423.72 263.1 418.68 263.1 417.56 258.21 422.54 258.21 423.72 263.1" fill="#dbdbdb"></polygon>
                <polygon points="425.33 269.76 420.19 269.76 419.08 264.88 424.15 264.88 425.33 269.76" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="426.94 276.42 421.7 276.42 420.59 271.54 425.76 271.54 426.94 276.42" fill="#a6a6a6"></polygon>
                <polygon points="428.54 283.09 423.22 283.09 422.11 278.2 427.36 278.2 428.54 283.09" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="428.69 256.44 423.74 256.44 422.54 251.55 427.42 251.55 428.69 256.44" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="430.42 263.1 425.37 263.1 424.17 258.21 429.15 258.21 430.42 263.1" fill="#a6a6a6"></polygon>
                <polygon points="432.15 269.76 427.01 269.76 425.81 264.88 430.88 264.88 432.15 269.76" fill="#dbdbdb"></polygon>
                <polygon points="433.88 276.42 428.65 276.42 427.45 271.54 432.61 271.54 433.88 276.42" fill="#263238"></polygon>
                <polygon points="435.61 283.09 430.29 283.09 429.08 278.2 434.34 278.2 435.61 283.09" fill="#dbdbdb"></polygon>
                <polygon points="401.92 283.09 362.34 283.09 361.57 251.55 396.5 251.55 401.92 283.09" fill="#263238"></polygon>
                <polygon points="366.74 255.13 364.17 255.13 364.15 254.47 366.71 254.47 366.74 255.13" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <rect x="280.67" y="294.87" width="164.93" height="143.15" fill="#455a64"></rect>
                <rect x="333.35" y="310.69" width="59.56" height="59.56" fill="#37474f"></rect>
                <path d="M363.69,319a22.51,22.51,0,1,0,22.5,22.5A22.5,22.5,0,0,0,363.69,319Zm0,38.3a15.8,15.8,0,1,1,15.79-15.8A15.79,15.79,0,0,1,363.69,357.3Z" fill="#263238"></path>
                <rect x="361.75" y="324.31" width="3.89" height="17.19" fill="#263238"></rect>
                <rect x="355.67" y="326.83" width="3.89" height="17.19" transform="translate(-132.44 351.12) rotate(-45)" fill="#263238"></rect>
                <rect x="353.15" y="332.91" width="3.89" height="17.19" transform="translate(13.58 696.6) rotate(-90)" fill="#263238"></rect>
                <rect x="355.67" y="338.99" width="3.89" height="17.19" transform="translate(364.7 846.24) rotate(-135)" fill="#263238"></rect>
                <rect x="361.75" y="341.51" width="3.89" height="17.19" transform="translate(727.38 700.21) rotate(180)" fill="#263238"></rect>
                <rect x="367.83" y="338.99" width="3.89" height="17.19" transform="translate(877.02 331.9) rotate(135)" fill="#263238"></rect>
                <rect x="370.35" y="332.91" width="3.89" height="17.19" transform="translate(713.8 -30.78) rotate(90)" fill="#263238"></rect>
                <rect x="367.83" y="326.83" width="3.89" height="17.19" transform="translate(345.49 -163.22) rotate(45)" fill="#263238"></rect>
                <path d="M363.13,318a22.5,22.5,0,1,0,22.51,22.5A22.5,22.5,0,0,0,363.13,318Zm0,38.29a15.79,15.79,0,1,1,15.79-15.79A15.78,15.78,0,0,1,363.13,356.26Z" fill="#455a64"></path>
                <rect x="361.19" y="323.28" width="3.89" height="17.19" fill="#455a64"></rect>
                <rect x="355.11" y="325.79" width="3.89" height="17.19" transform="translate(-131.87 350.41) rotate(-45)" fill="#455a64"></rect>
                <rect x="352.59" y="331.88" width="3.89" height="17.19" transform="translate(14.06 695) rotate(-90)" fill="#455a64"></rect>
                <rect x="355.11" y="337.96" width="3.89" height="17.19" transform="translate(364.47 844.08) rotate(-135)" fill="#455a64"></rect>
                <rect x="361.19" y="340.47" width="3.89" height="17.19" transform="translate(726.26 698.14) rotate(180)" fill="#455a64"></rect>
                <rect x="367.27" y="337.96" width="3.89" height="17.19" transform="translate(875.33 330.53) rotate(135)" fill="#455a64"></rect>
                <rect x="369.79" y="331.88" width="3.89" height="17.19" transform="translate(712.2 -31.26) rotate(90)" fill="#455a64"></rect>
                <rect x="367.27" y="325.79" width="3.89" height="17.19" transform="translate(344.59 -163.13) rotate(45)" fill="#455a64"></rect>
                <circle cx="363.13" cy="340.47" r="4.16" fill="#455a64"></circle>
                <path d="M367.29,340.47a4.28,4.28,0,0,1-7.25,3.09,4.28,4.28,0,0,1,0-6.18,4.28,4.28,0,0,1,7.25,3.09Zm0,0a4.16,4.16,0,0,0-1.24-2.92,4.06,4.06,0,1,0,1.24,2.92Z" fill="#263238"></path>
                <path d="M329,315.8a.84.84,0,1,0,1-.85A.85.85,0,0,0,329,315.8Zm0,0a1.09,1.09,0,1,1,1,1.09A1.1,1.1,0,0,1,329,315.8Z" fill="#37474f"></path>
                <path d="M329,325.65a.84.84,0,1,0,1-.85A.85.85,0,0,0,329,325.65Zm0,0a1.09,1.09,0,1,1,1,1.09A1.1,1.1,0,0,1,329,325.65Z" fill="#37474f"></path>
                <path d="M329,335.5a.85.85,0,0,0,1,.84.85.85,0,1,0-1-.84Zm0,0a1.09,1.09,0,1,1,1,1.09A1.1,1.1,0,0,1,329,335.5Z" fill="#37474f"></path>
                <path d="M329,345.35a.85.85,0,0,0,1,.84.85.85,0,0,0,0-1.68A.85.85,0,0,0,329,345.35Zm0,0a1.09,1.09,0,0,1,1-1.09,1.1,1.1,0,1,1-1,1.09Z" fill="#37474f"></path>
                <path d="M329,355.2a.84.84,0,1,0,1.68,0,.84.84,0,1,0-1.68,0Zm0,0a1.09,1.09,0,0,1,1-1.09,1.1,1.1,0,1,1-1,1.09Z" fill="#37474f"></path>
                <path d="M329,365.05a.84.84,0,1,0,1-.84A.85.85,0,0,0,329,365.05Zm0,0a1.09,1.09,0,0,1,1-1.09,1.1,1.1,0,1,1-1,1.09Z" fill="#37474f"></path>
                <path d="M395.3,315.8a.85.85,0,0,0,1,.84.86.86,0,0,0,0-1.69A.85.85,0,0,0,395.3,315.8Zm0,0a1.1,1.1,0,1,1,1,1.09A1.1,1.1,0,0,1,395.3,315.8Z" fill="#37474f"></path>
                <path d="M395.3,325.65a.85.85,0,0,0,1,.84.86.86,0,0,0,0-1.69A.85.85,0,0,0,395.3,325.65Zm0,0a1.1,1.1,0,1,1,1,1.09A1.1,1.1,0,0,1,395.3,325.65Z" fill="#37474f"></path>
                <path d="M395.3,335.5a.85.85,0,1,0,1-.84A.84.84,0,0,0,395.3,335.5Zm0,0a1.1,1.1,0,1,1,2.19,0,1.1,1.1,0,1,1-2.19,0Z" fill="#37474f"></path>
                <path d="M395.3,345.35a.85.85,0,1,0,1-.84A.85.85,0,0,0,395.3,345.35Zm0,0a1.1,1.1,0,1,1,1,1.09A1.1,1.1,0,0,1,395.3,345.35Z" fill="#37474f"></path>
                <path d="M395.3,355.2a.85.85,0,0,0,1,.85.87.87,0,0,0,.72-.85.85.85,0,1,0-1.69,0Zm0,0a1.1,1.1,0,1,1,1,1.09A1.1,1.1,0,0,1,395.3,355.2Z" fill="#37474f"></path>
                <path d="M395.3,365.05a.85.85,0,0,0,1,.85.86.86,0,0,0,0-1.69A.85.85,0,0,0,395.3,365.05Zm0,0a1.1,1.1,0,1,1,2.19,0,1.1,1.1,0,1,1-2.19,0Z" fill="#37474f"></path>
                <path d="M387.76,306.32a.85.85,0,1,0,.84,1A.85.85,0,0,0,387.76,306.32Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,387.76,306.32Z" fill="#37474f"></path>
                <path d="M377.91,306.32a.85.85,0,0,0-.85,1,.87.87,0,0,0,.85.72.85.85,0,1,0,0-1.69Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,377.91,306.32Z" fill="#37474f"></path>
                <path d="M368.06,306.32a.85.85,0,0,0-.85,1,.86.86,0,0,0,1.69,0A.85.85,0,0,0,368.06,306.32Zm0,0a1.1,1.1,0,1,1,0,2.19,1.1,1.1,0,1,1,0-2.19Z" fill="#37474f"></path>
                <path d="M358.21,306.32a.85.85,0,0,0-.85,1,.86.86,0,0,0,1.69,0A.85.85,0,0,0,358.21,306.32Zm0,0a1.1,1.1,0,0,1,1.09,1,1.1,1.1,0,1,1-1.09-1Z" fill="#37474f"></path>
                <path d="M348.35,306.32a.85.85,0,0,0-.84,1,.86.86,0,0,0,1.69,0A.85.85,0,0,0,348.35,306.32Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,348.35,306.32Z" fill="#37474f"></path>
                <path d="M338.5,306.32a.85.85,0,0,0-.84,1,.86.86,0,0,0,1.69,0A.85.85,0,0,0,338.5,306.32Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,338.5,306.32Z" fill="#37474f"></path>
                <path d="M387.76,372.59a.86.86,0,0,0-.84,1,.85.85,0,0,0,1.68,0A.86.86,0,0,0,387.76,372.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,387.76,372.59Z" fill="#37474f"></path>
                <path d="M377.91,372.59a.86.86,0,0,0-.85,1,.85.85,0,0,0,.85.72.84.84,0,0,0,.84-.72A.86.86,0,0,0,377.91,372.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,377.91,372.59Z" fill="#37474f"></path>
                <path d="M368.06,372.59a.86.86,0,0,0-.85,1,.86.86,0,0,0,1.69,0A.86.86,0,0,0,368.06,372.59Zm0,0a1.1,1.1,0,1,1,0,2.19,1.1,1.1,0,1,1,0-2.19Z" fill="#37474f"></path>
                <path d="M358.21,372.59a.86.86,0,0,0-.85,1,.86.86,0,0,0,1.69,0A.86.86,0,0,0,358.21,372.59Zm0,0a1.1,1.1,0,0,1,1.09,1,1.1,1.1,0,1,1-1.09-1Z" fill="#37474f"></path>
                <path d="M348.35,372.59a.86.86,0,0,0-.84,1,.86.86,0,0,0,1.69,0A.86.86,0,0,0,348.35,372.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,348.35,372.59Z" fill="#37474f"></path>
                <path d="M338.5,372.59a.86.86,0,0,0-.84,1,.86.86,0,0,0,1.69,0A.86.86,0,0,0,338.5,372.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,338.5,372.59Z" fill="#37474f"></path>
                <path d="M333.58,411.59a.85.85,0,1,0,.84,1A.85.85,0,0,0,333.58,411.59Zm0,0a1.1,1.1,0,1,1,0,2.19,1.1,1.1,0,1,1,0-2.19Z" fill="#37474f"></path>
                <path d="M323.73,411.59a.85.85,0,1,0,.84,1A.85.85,0,0,0,323.73,411.59Zm0,0a1.1,1.1,0,0,1,1.09,1,1.1,1.1,0,1,1-1.09-1Z" fill="#37474f"></path>
                <path d="M343.43,411.59a.85.85,0,1,0,.84,1A.85.85,0,0,0,343.43,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,343.43,411.59Z" fill="#37474f"></path>
                <path d="M353.28,411.59a.85.85,0,0,0-.84,1,.83.83,0,0,0,.84.72.84.84,0,0,0,.84-.72A.85.85,0,0,0,353.28,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,353.28,411.59Z" fill="#37474f"></path>
                <path d="M363.13,411.59a.85.85,0,0,0-.84,1,.83.83,0,0,0,.84.72.85.85,0,1,0,0-1.69Zm0,0a1.1,1.1,0,1,1,0,2.19,1.1,1.1,0,1,1,0-2.19Z" fill="#37474f"></path>
                <path d="M373,411.59a.85.85,0,1,0,.85,1A.85.85,0,0,0,373,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,373,411.59Z" fill="#37474f"></path>
                <path d="M382.83,411.59a.85.85,0,1,0,.85,1A.85.85,0,0,0,382.83,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,382.83,411.59Z" fill="#37474f"></path>
                <path d="M392.69,411.59a.85.85,0,1,0,.84,1A.85.85,0,0,0,392.69,411.59Zm0,0a1.1,1.1,0,0,1,1.09,1,1.1,1.1,0,1,1-1.09-1Z" fill="#37474f"></path>
                <path d="M402.54,411.59a.85.85,0,1,0,.84,1A.85.85,0,0,0,402.54,411.59Zm0,0a1.1,1.1,0,1,1,0,2.19,1.1,1.1,0,1,1,0-2.19Z" fill="#37474f"></path>
                <path d="M412.39,411.59a.85.85,0,1,0,.84,1A.85.85,0,0,0,412.39,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,412.39,411.59Z" fill="#37474f"></path>
                <path d="M422.24,411.59a.85.85,0,0,0-.84,1,.83.83,0,0,0,.84.72.84.84,0,0,0,.84-.72A.85.85,0,0,0,422.24,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,422.24,411.59Z" fill="#37474f"></path>
                <path d="M432.09,411.59a.85.85,0,0,0-.84,1,.83.83,0,0,0,.84.72.85.85,0,1,0,0-1.69Zm0,0a1.1,1.1,0,1,1,0,2.19,1.1,1.1,0,1,1,0-2.19Z" fill="#37474f"></path>
                <path d="M441.94,411.59a.85.85,0,1,0,.85,1A.85.85,0,0,0,441.94,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,441.94,411.59Z" fill="#37474f"></path>
                <path d="M313.87,411.59a.85.85,0,1,0,.85,1A.85.85,0,0,0,313.87,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,313.87,411.59Z" fill="#37474f"></path>
                <path d="M304,411.59a.85.85,0,0,0-.84,1,.84.84,0,0,0,.84.72.85.85,0,1,0,0-1.69Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,304,411.59Z" fill="#37474f"></path>
                <path d="M294.17,411.59a.85.85,0,0,0-.84,1,.83.83,0,0,0,.84.72.85.85,0,1,0,0-1.69Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,294.17,411.59Z" fill="#37474f"></path>
                <path d="M284.32,411.59a.85.85,0,0,0-.84,1,.83.83,0,0,0,.84.72.84.84,0,0,0,.84-.72A.85.85,0,0,0,284.32,411.59Zm0,0a1.1,1.1,0,1,1-1.09,1A1.1,1.1,0,0,1,284.32,411.59Z" fill="#37474f"></path>
                <path d="M423.22,353.39a.86.86,0,0,0-1-.85.85.85,0,0,0-.72.85.84.84,0,0,0,.72.84A.86.86,0,0,0,423.22,353.39Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,353.39Z" fill="#37474f"></path>
                <path d="M423.22,343.54a.86.86,0,0,0-1-.85.86.86,0,0,0,0,1.69A.86.86,0,0,0,423.22,343.54Zm0,0a1.1,1.1,0,1,1-2.19,0,1.1,1.1,0,1,1,2.19,0Z" fill="#37474f"></path>
                <path d="M423.22,363.24a.86.86,0,0,0-1-.84.85.85,0,0,0,0,1.68A.86.86,0,0,0,423.22,363.24Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,363.24Z" fill="#37474f"></path>
                <path d="M423.22,373.09a.86.86,0,0,0-1-.84.85.85,0,0,0,0,1.68A.86.86,0,0,0,423.22,373.09Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,373.09Z" fill="#37474f"></path>
                <path d="M423.22,382.94a.86.86,0,0,0-1-.84.84.84,0,0,0-.72.84.85.85,0,0,0,.72.85A.86.86,0,0,0,423.22,382.94Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,382.94Z" fill="#37474f"></path>
                <path d="M423.22,392.79a.86.86,0,0,0-1-.84.86.86,0,0,0,0,1.69A.86.86,0,0,0,423.22,392.79Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,392.79Z" fill="#37474f"></path>
                <path d="M423.22,402.65a.86.86,0,0,0-1-.85.86.86,0,0,0,0,1.69A.86.86,0,0,0,423.22,402.65Zm0,0a1.1,1.1,0,0,1-1,1.09,1.1,1.1,0,1,1,1-1.09Z" fill="#37474f"></path>
                <path d="M423.22,412.5a.86.86,0,0,0-1-.85.86.86,0,0,0,0,1.69A.86.86,0,0,0,423.22,412.5Zm0,0a1.1,1.1,0,1,1-2.19,0,1.1,1.1,0,1,1,2.19,0Z" fill="#37474f"></path>
                <path d="M423.22,422.35a.86.86,0,0,0-1-.85.85.85,0,0,0-.72.85.84.84,0,0,0,.72.84A.86.86,0,0,0,423.22,422.35Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,422.35Z" fill="#37474f"></path>
                <path d="M423.22,432.2a.86.86,0,0,0-1-.84.85.85,0,0,0,0,1.68A.86.86,0,0,0,423.22,432.2Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,432.2Z" fill="#37474f"></path>
                <path d="M423.22,333.69a.86.86,0,0,0-1-.85.86.86,0,0,0,0,1.69A.86.86,0,0,0,423.22,333.69Zm0,0a1.1,1.1,0,0,1-1,1.09,1.1,1.1,0,1,1,1-1.09Z" fill="#37474f"></path>
                <path d="M423.22,323.83a.86.86,0,0,0-1-.84.86.86,0,0,0,0,1.69A.86.86,0,0,0,423.22,323.83Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,323.83Z" fill="#37474f"></path>
                <path d="M423.22,314a.86.86,0,0,0-1-.84.84.84,0,0,0-.72.84.85.85,0,0,0,.72.85A.86.86,0,0,0,423.22,314Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,314Z" fill="#37474f"></path>
                <path d="M423.22,304.13a.86.86,0,0,0-1-.84.85.85,0,0,0,0,1.68A.86.86,0,0,0,423.22,304.13Zm0,0a1.1,1.1,0,1,1-1-1.09A1.1,1.1,0,0,1,423.22,304.13Z" fill="#37474f"></path>

                <path d="M283.61,369.4c-11.21,15.18-22.9,32.79-25.74,51.83,0,.28.43.38.49.09a113,113,0,0,1,5-15.24c7.37-2,14.6-3.69,18.47-11,3.94-7.52,3.44-17.32,2.69-25.51C284.43,369.13,283.84,369.11,283.61,369.4Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M263.43,405.94c5-11.3,11.5-22.64,19.71-31.93.06-.06.14,0,.09.09-5.37,6.61-9.46,13.82-13.45,21.15,3.71-3.76,7.77-7.14,11.29-11.13.07-.08.18,0,.11.11-3.49,4.19-7.3,8.74-11.85,11.84q-1.83,3.38-3.69,6.76c.3-.17.62-.32.93-.48.06,0,.1.06.05.08-.35.18-.69.37-1.05.52-.59,1.06-1.17,2.13-1.78,3.2C263.65,406.39,263.32,406.18,263.43,405.94Z" fill="#263238"></path>
                <path d="M267.18,402a66.06,66.06,0,0,0,8.6-5.75c.07-.05.18.05.1.11a45.15,45.15,0,0,1-8.58,5.85A.12.12,0,0,1,267.18,402Z" fill="#263238"></path>
                <path d="M256.57,418.4c0,.23.42.22.41,0,.06-6.27,0-12.57.1-18.86,1.61-3.24,5.05-5,7.45-7.64a32.84,32.84,0,0,0,4.47-6.22,44.61,44.61,0,0,0,5.19-17.29c.66-6.71-1.88-28.83-4.71-28.06-1.47.4-6.21,11.36-7.47,15a104.27,104.27,0,0,0-4.46,18.37C255.26,388.41,255.45,403.55,256.57,418.4Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M256.53,391.36l0,0c.34-7.93,2.13-15.94,3.8-23.63,1.8-8.29,3.91-16.09,8.17-23.48a.12.12,0,0,1,.21.12,87.36,87.36,0,0,0-6.56,18c.28-.45.63-.87.93-1.3.11-.16.39,0,.27.16a10.9,10.9,0,0,1-1.33,1.89l-.05,0c-1.47,5.92-2.53,12-3.43,18l.2-.24c.05-.05.13,0,.09.07s-.23.4-.35.6c-.46,3.07-.88,6.13-1.3,9.17,5.46-4.67,10.12-10.17,12.75-16.95,0,0,.07,0,.06,0-2.58,7-6.65,13.28-12.9,17.55-.11.82-.23,1.64-.34,2.45,0,.17-.3.12-.3,0,0-.72,0-1.45.07-2.17C256.47,391.55,256.43,391.44,256.53,391.36Z" fill="#263238"></path>
                <path d="M259.1,379.87a127.08,127.08,0,0,0,11.13-19.17c0-.08.16,0,.13.06-2.57,6.84-6.17,13.77-11.05,19.27C259.21,380.14,259,380,259.1,379.87Z" fill="#263238"></path>
                <path d="M264.65,359.16c1.73-3.06,3.55-6,5.18-9.15,0-.09.16,0,.13.07a47.6,47.6,0,0,1-5,9.24A.15.15,0,0,1,264.65,359.16Z" fill="#263238"></path>
                <path d="M264,360.49a0,0,0,1,1,0,.06S263.94,360.49,264,360.49Z" fill="#263238"></path>
                <path d="M252.33,400.27a64,64,0,0,1-1.46,12.35c.06.44.12.89.19,1.35a59.06,59.06,0,0,0,2.21-13.77.42.42,0,0,0,0-.42h0a91.2,91.2,0,0,0-1.39-18.65c-1.94-11.52-6.18-22.58-14.21-31.21a.57.57,0,0,0-.89.18c-3.24,8.24-2.11,18.08.65,26.3a48,48,0,0,0,6.37,12.49C246.58,392.76,250.09,396,252.33,400.27Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M238.64,353.76c7.61,12.36,12.91,27,13.56,41.61,0,.26-.43.33-.46.06-.18-1.31-.38-2.61-.59-3.9h0c-3.15-3-6.38-6.2-8.64-10,0-.07.06-.14.11-.08A105.16,105.16,0,0,0,251,390.6c-.54-3.23-1.19-6.4-2-9.53l-.6-.71c-.14-.16.08-.38.22-.23l.19.21a109.05,109.05,0,0,0-4-12.64c-1.93-2.31-4.1-4.31-5.51-7.1,0-.07.05-.12.1-.06a57.19,57.19,0,0,0,3.52,4.68c.51.58,1.08,1.14,1.61,1.71a96.5,96.5,0,0,0-6.24-13A.18.18,0,0,1,238.64,353.76Z" fill="#263238"></path>
                <path d="M240.18,369c2.46,3.31,5.26,6.47,7.55,9.89.07.1-.08.2-.16.13A50.5,50.5,0,0,1,240,369.1C239.94,369,240.11,368.91,240.18,369Z" fill="#263238"></path>
                <path d="M248.08,379.67l.1.1c.07.08,0,.19-.11.12l-.11-.11C247.89,379.71,248,379.6,248.08,379.67Z" fill="#263238"></path>
                <path d="M226.71,377.52a38,38,0,0,0,8,18.85,20,20,0,0,0,6.52,5.08c2.82,1.4,5.78,3,8.69,4.15A57,57,0,0,1,253,416.54c.1-.32.19-.65.29-1-1.88-16.1-12.29-29.85-25.62-38.68A.64.64,0,0,0,226.71,377.52Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M231.31,382.27c-.09-.08,0-.25.14-.17a54.49,54.49,0,0,1,17,20.92.19.19,0,0,1-.33.19c-.62-1.08-1.25-2.14-1.89-3.17h0c-3-.57-5.92-2.71-8.18-4.75-.06-.05,0-.14.09-.09a19.1,19.1,0,0,0,3.85,2.71c1.24.63,2.57,1,3.87,1.53q-1.69-2.69-3.53-5.17a35.59,35.59,0,0,1-9.14-5.14c-.09-.06,0-.2.08-.15a89.11,89.11,0,0,0,8.5,4.54,77.46,77.46,0,0,0-6-7c-1-.54-2.08-1.09-3.08-1.68-.08,0,0-.18.06-.15a10.82,10.82,0,0,1,2.37,1.16C233.88,384.65,232.64,383.45,231.31,382.27Z" fill="#263238"></path>
                <path d="M231.6,387.83a3.68,3.68,0,0,1,.51.37s0,.1-.07.08a4,4,0,0,1-.53-.3C231.42,387.92,231.5,387.77,231.6,387.83Z" fill="#263238"></path>
                <polygon points="274.11 451.39 237.6 451.39 240.55 418.15 241.19 410.82 270.51 410.82 271.16 418.15 274.11 451.39" fill="#455a64"></polygon>
                <polygon points="271.16 418.15 240.55 418.15 241.19 410.82 270.51 410.82 271.16 418.15" fill="#263238"></polygon>
                <rect x="239.45" y="405.69" width="32.81" height="7.26" fill="#455a64"></rect>

                <path d="M196.57,451.32c-2.55.8-74.81-.75-77.53-2.55-1-.67-1.42-11.22-1.52-24,0-2.75,0-5.59,0-8.48,0-3.42,0-6.88.07-10.24.19-15.1.64-28.38.64-28.38l47,.87L164.71,407l-.2,10.24-.4,8.47s27.39,13,30.16,15.27S199.1,450.54,196.57,451.32Z" fill="#ffc4c0"></path>
                <path d="M196.57,451.32c-2.55.8-74.81-.75-77.53-2.55-1-.67-1.42-11.22-1.52-24,0-2.75,0-5.59,0-8.48,0-3.42,0-6.88.07-10.24l47.14.87-.2,10.24-.4,8.47s27.39,13,30.16,15.27S199.1,450.54,196.57,451.32Z" fill="#fff"></path>
                <path d="M196.78,451.44c-1.65.51-32.44.05-54.84-.83-5.18-.21-9.92-.44-13.74-.68-5.27-.34-8.8-.69-9.37-1.07-1.26-.84-1.58-16.3-1.57-32.62l47.28.88-.4,8.52s27.55,13,30.34,15.35S199.33,450.63,196.78,451.44Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M195.8,448.51c-12.17-.56-61.32-1.67-73.4-1.3-.1,0-.1.07,0,.08,12,.82,61.21,1.53,73.4,1.42C196.06,448.7,196.06,448.52,195.8,448.51Z" fill="#263238"></path>
                <path d="M165.92,423c-4.78-1-10.66-.39-14.39,2.95-.15.13,0,.33.2.29a67.26,67.26,0,0,1,14.15-2.63A.31.31,0,0,0,165.92,423Z" fill="#263238"></path>
                <path d="M169.33,425.13c-4.77-1-10.65-.39-14.39,2.95-.14.13,0,.33.2.29a67.37,67.37,0,0,1,14.16-2.64A.3.3,0,0,0,169.33,425.13Z" fill="#263238"></path>
                <path d="M172.75,427.29c-4.78-1-10.66-.39-14.39,2.95-.15.13,0,.33.2.29a67.27,67.27,0,0,1,14.15-2.64A.3.3,0,0,0,172.75,427.29Z" fill="#263238"></path>
                <path d="M176.16,429.45c-4.77-1-10.65-.39-14.39,2.95-.14.13,0,.33.2.28a68,68,0,0,1,14.16-2.63A.3.3,0,0,0,176.16,429.45Z" fill="#263238"></path>
                <path d="M175,412.08c-1.83-2.64-5.36-1.55-7.4.09A25.64,25.64,0,0,0,160,423a.13.13,0,0,0,.11.18c0,.36.33.76.74.61,4-1.38,8.43-2.36,11.87-4.92C174.72,417.36,176.73,414.57,175,412.08Zm-8.55,8.37c-2,.73-3.91,1.36-5.83,2.15,1.49-2.1,2.88-4.26,4.58-6.22a30,30,0,0,1,2.6-2.69c1.46-1.26,7.16-3.91,6.2,1.34C173.49,417.91,168.78,419.59,166.46,420.45Z" fill="#263238"></path>
                <path d="M147.84,421.48c3.91,1.77,8.45,1.78,12.65,2.28a.6.6,0,0,0,.59-.76.14.14,0,0,0,.07-.2,25.67,25.67,0,0,0-9.76-8.94c-2.34-1.17-6-1.48-7.24,1.49C143,418.15,145.55,420.45,147.84,421.48ZM145.75,418c-2.06-4.92,4.08-3.56,5.77-2.63a27.09,27.09,0,0,1,3.11,2.07,76.22,76.22,0,0,1,5.81,5.1c-2-.37-4.1-.56-6.15-.86C151.83,421.35,146.88,420.73,145.75,418Z" fill="#263238"></path>
                <path d="M159.8,451.17c-6.08-.15-12.24-.35-17.86-.57-5.18-.19-9.92-.43-13.74-.67-5.27-.34-8.81-.69-9.37-1.07-1.1-.74-1.49-12.68-1.55-26.6C128,429.43,149.08,443.83,159.8,451.17Z" fillOpacity="0.2"></path>
                <path d="M168.75,425.09l-53.68.05S118,304.3,117.43,301.48C116.05,294.76,90,176.6,90,176.6H135.5s5.64,30.34,6.15,32.23c7.08,25.84,20.91,70,23.73,86.34C170.27,323.52,168.75,425.09,168.75,425.09Z" fill="#263238"></path>
                <path d="M167,416.12c-8.37-.22-38-1.1-49.2-.27a.08.08,0,0,0,0,.16c3.82.62,40.83.45,49.2.34A.12.12,0,1,0,167,416.12Z" fill="#37474f"></path>
                <path d="M123.74,298.81c-1.61-6.53-24.26-113.63-24.69-115.26a.11.11,0,0,0-.21,0c1.2,6.54,22,108.12,23.78,114.54s-1.14,111.64-1,113.35a.38.38,0,0,0,.76,0C122.66,399.08,125.32,305.27,123.74,298.81Z" fill="#37474f"></path>
                <polygon points="116.22 295.86 129.24 176.6 90.04 176.6 116.22 295.86" fill="#37474f"></polygon>
                <path d="M140.89,451.51c-2.55.79-74.8-.76-77.53-2.55-1-.68-1.41-11.22-1.52-24,0-2.75,0-5.58,0-8.48,0-3.42,0-6.88.07-10.24.19-15.09.64-28.38.64-28.38l47.05.87L109,407.14l-.2,10.24-.4,8.48s27.4,13,30.16,15.26S143.43,450.73,140.89,451.51Z" fill="#ffc4c0"></path>
                <path d="M140.89,451.51c-2.55.79-74.8-.76-77.53-2.55-1-.68-1.41-11.22-1.52-24,0-2.75,0-5.58,0-8.48,0-3.42,0-6.88.07-10.24l47.14.87-.2,10.24-.4,8.48s27.4,13,30.16,15.26S143.43,450.73,140.89,451.51Z" fill="#fff"></path>
                <path d="M141.11,451.62c-1.66.51-32.44.05-54.84-.83-5.19-.2-9.93-.43-13.75-.67-5.27-.34-8.8-.7-9.37-1.07-1.26-.84-1.58-16.31-1.56-32.62l47.27.87-.4,8.52s27.55,13,30.34,15.35S143.65,450.82,141.11,451.62Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M140.12,448.69c-12.17-.56-61.32-1.67-73.4-1.3-.1,0-.1.07,0,.08,12,.82,61.21,1.53,73.4,1.42C140.38,448.89,140.38,448.7,140.12,448.69Z" fill="#263238"></path>
                <path d="M110.24,423.16c-4.78-1-10.65-.39-14.39,2.94-.14.13,0,.34.2.29a67.35,67.35,0,0,1,14.16-2.63A.3.3,0,0,0,110.24,423.16Z" fill="#263238"></path>
                <path d="M113.65,425.31c-4.77-1-10.65-.38-14.38,2.95-.15.13,0,.34.19.29a67.35,67.35,0,0,1,14.16-2.63A.31.31,0,0,0,113.65,425.31Z" fill="#263238"></path>
                <path d="M117.07,427.47c-4.78-1-10.65-.38-14.39,2.95-.14.13,0,.34.2.29A67.35,67.35,0,0,1,117,428.08.31.31,0,0,0,117.07,427.47Z" fill="#263238"></path>
                <path d="M120.48,429.63c-4.77-1-10.65-.38-14.38,2.95-.15.13,0,.33.19.29a67.35,67.35,0,0,1,14.16-2.63A.31.31,0,0,0,120.48,429.63Z" fill="#263238"></path>
                <path d="M119.33,412.26c-1.83-2.63-5.36-1.54-7.4.1a25.61,25.61,0,0,0-7.62,10.82.13.13,0,0,0,.11.17c0,.37.33.76.74.62,4-1.38,8.43-2.37,11.87-4.93C119.05,417.54,121.06,414.75,119.33,412.26Zm-8.55,8.38c-1.94.72-3.91,1.35-5.83,2.15a73.28,73.28,0,0,1,4.59-6.22,25.41,25.41,0,0,1,2.6-2.69c1.45-1.27,7.15-3.91,6.2,1.33C117.81,418.1,113.1,419.77,110.78,420.64Z" fill="#263238"></path>
                <path d="M92.16,421.67c3.91,1.76,8.46,1.78,12.65,2.27a.6.6,0,0,0,.59-.76.13.13,0,0,0,.07-.19A25.59,25.59,0,0,0,95.72,414c-2.34-1.16-6-1.47-7.25,1.49C87.31,418.33,89.87,420.63,92.16,421.67Zm-2.09-3.47c-2.06-4.91,4.08-3.55,5.77-2.63A27.17,27.17,0,0,1,99,417.65a74.24,74.24,0,0,1,5.82,5.1c-2-.37-4.11-.57-6.16-.86C96.16,421.54,91.2,420.91,90.07,418.2Z" fill="#263238"></path>
                <path d="M111.59,424.55l-52.09-1S74.94,235,74,225c-1.4-15.05-4.37-21.63,5.83-48.72l51.1.43s.19,29.43-5.21,37.84c0,0-4.81,2.92-7.15,4.21Z" fill="#263238"></path>
                <path d="M110.16,415.3c-7.9-.35-5.63-.36-13.53-.63-3.87-.13-29.28-.95-32.91-.44a.08.08,0,0,0,0,.16c3.6.68,29,1.07,32.89,1.12,7.9.12,5.63,0,13.54,0A.12.12,0,0,0,110.16,415.3Z" fill="#37474f"></path>
                <path d="M129,212.39a97.66,97.66,0,0,0-14.43,8.19.17.17,0,0,0,.17.29c4.88-2.59,9.86-5.08,14.59-7.92A.33.33,0,0,0,129,212.39Z" fill="#37474f"></path>
                <path d="M131.3,178.66c0-.17-.31-.21-.31,0a166.61,166.61,0,0,1-.84,16.69c-.23,2.57-2.3,16.79-6.76,15.76-.75-.18-1.13-.91-1.25-2a18.15,18.15,0,0,1,.59-4.36c.5-3,.9-6,1.15-9a81.61,81.61,0,0,0-.18-16.83.15.15,0,0,0-.29,0,152.91,152.91,0,0,1-1.36,23.23c-.56,3.69-2.42,9.26,1.45,9.83s6.49-8.75,7-11.3A85,85,0,0,0,131.3,178.66Z" fill="#37474f"></path>
                <path d="M123.5,211.47a25,25,0,0,0-1,2.95,6.93,6.93,0,0,0-.42,2.42c0,.16.23.17.31.07a8.45,8.45,0,0,0,1-2.36c.34-.91.7-1.82,1-2.74A.5.5,0,0,0,123.5,211.47Z" fill="#37474f"></path>
                <path d="M80.56,225.25c-.29-3.73-.83-7.45-1.17-11.18a69.32,69.32,0,0,1,1.77-22.32c0-.13-.18-.19-.21-.05-1.36,6.47-2.56,12.93-2.36,19.57s1.25,13.09,1.23,19.69-.67,13.35-1.22,20S77.49,264.34,76.94,271c-2.23,26.9-4.33,53.81-6.47,80.72q-.89,11.26-1.73,22.54c-.59,7.64-1.47,26.5-1.9,34.15,0,.66,1,.63,1,0,1.39-13.49,2.52-38.25,3.63-51.77s2.13-26.77,3.2-40.15,2.12-26.77,3.16-40.16q.78-10,1.54-20.07c.5-6.67,1.12-13.34,1.34-20A96.28,96.28,0,0,0,80.56,225.25Z" fill="#37474f"></path>
                <path d="M105.24,186.43a.13.13,0,0,0-.23,0c-.5.81-.44,2.17-.61,3.11a22.9,22.9,0,0,1-.87,3.35,13.39,13.39,0,0,1-3.63,5.77c-3.76,3.34-8.73,4.51-13.52,5.57-.18,0-.12.28,0,.3a21.72,21.72,0,0,0,8.34-1.13,17.42,17.42,0,0,0,6.49-3.43,11.78,11.78,0,0,0,3.58-6.12,21.37,21.37,0,0,0,.47-3.74c0-.61,0-1.21.06-1.81A4.73,4.73,0,0,0,105.24,186.43Z" fill="#37474f"></path>
                <path d="M118.12,114.29c10,9.78,37.38,27.48,45.62,27.82,6.3.26,26.17-12.5,33.61-18.76,3.19-2.69-12.49-22-15.14-20.3-8.17,5.32-21.91,13.31-24.61,14.34-1.9.72-12.29-3.65-33-10.83C113,102.59,112.48,108.78,118.12,114.29Z" fill="#ffc4c0"></path>
                <path d="M196,127.8,178.5,103.56S159.73,115,156.91,116.41c-1.19.6-24.26-7.86-34.56-11.09-6.77-2.12-13.18,1.26-3.43,10.9,5.71,5.64,36.54,28.27,44.65,27.67C170,143.42,196,127.8,196,127.8Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M190.34,129.49c-1.52-1.54-6.69-8.82-8-10.65s-6.1-9.35-7.4-11.23c-.07-.1.06-.23.13-.13,1.32,1.84,6.75,9,8,10.8s6.35,9.15,7.29,11.14A.06.06,0,0,1,190.34,129.49Z" fill="#263238"></path>
                <path d="M151.79,139.44c-12.24-6.76-28.83-19.23-32.87-23.22-6.63-6.56-5.79-10.22-2.44-11.22Z" fill="#020100" fillOpacity="0.1"></path>
                <path d="M76.78,179.37c.14.17,62.71,2.14,63.31,1.5a42.79,42.79,0,0,0,.25-5.29s-12.16-66.74-15.47-70.4c-2.9-3.21-10.92-5-16-2.19-10.67,5.79-31.51,68.48-31,70.93C77.89,173.92,76.6,179.15,76.78,179.37Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M139,176.33c-1.95-.15-3.92-.09-5.88-.17s-3.73-.22-5.6-.28c-3.83-.11-7.66-.19-11.49-.31-7.66-.23-15.32-.53-23-.8l-6.41-.25c-2.23-.09-4.47,0-6.7-.12a.09.09,0,0,0,0,.17c1.95.15,3.9.43,5.86.55l5.6.33q5.73.33,11.48.55,11.49.48,23,.66c2.15,0,4.29,0,6.43,0s4.47.08,6.7,0C139.13,176.57,139.16,176.34,139,176.33Z" fill="#263238"></path>
                <polygon points="191.17 164.32 212.2 120.46 227.64 111.86 206.61 155.71 191.17 164.32" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="191.17 164.32 212.2 120.46 227.64 111.86 206.61 155.71 191.17 164.32" fillOpacity="0.2"></polygon>
                <rect x="133.77" y="129" width="83.94" height="40.8" transform="translate(-34.95 243.29) rotate(-64.39)" style={{ fill: Props.colorTheme.activeColor }}></rect>
                <rect x="133.77" y="129" width="83.94" height="40.8" transform="translate(-34.95 243.29) rotate(-64.39)" fillOpacity="0.2"></rect>
                <rect x="168.65" y="107.05" width="35.24" height="40.8" transform="translate(-9.18 240.32) rotate(-64.39)" fillOpacity="0.2"></rect>
                <path d="M177,106.14q2.22,5.42,4.52,10.77l4.6,10.69,4.69,10.62q2.36,5.29,4.77,10.53Q193.33,143.33,191,138l-4.61-10.69-4.68-10.61Q179.4,111.39,177,106.14Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M208.7,121.35q-5.59,1.4-11.2,2.88l-11.21,3-11.22,3.1q-5.61,1.58-11.23,3.22,5.59-1.39,11.2-2.87l11.21-3,11.22-3.1Q203.08,123,208.7,121.35Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <rect x="195.04" y="129.36" width="10.7" height="9.56" transform="translate(407.97 11.43) rotate(115.61)" fill="#ebebeb"></rect>
                <rect x="154.03" y="163.34" width="0.57" height="8.11" transform="translate(371.97 100.61) rotate(115.62)" fill="#263238"></rect>
                <rect x="153.43" y="164.58" width="0.57" height="8.11" transform="translate(372.23 102.91) rotate(115.61)" fill="#263238"></rect>
                <rect x="152.83" y="165.82" width="0.57" height="8.11" transform="translate(372.5 105.25) rotate(115.62)" fill="#263238"></rect>
                <rect x="152.24" y="167.07" width="0.57" height="8.11" transform="translate(372.77 107.55) rotate(115.61)" fill="#263238"></rect>
                <rect x="151.64" y="168.31" width="0.57" height="8.11" transform="translate(373.03 109.87) rotate(115.61)" fill="#263238"></rect>
                <rect x="151.04" y="169.56" width="0.57" height="8.11" transform="translate(373.31 112.23) rotate(115.62)" fill="#263238"></rect>
                <rect x="150.45" y="170.8" width="0.57" height="8.11" transform="translate(373.56 114.49) rotate(115.61)" fill="#263238"></rect>
                <rect x="149.85" y="172.05" width="0.57" height="8.11" transform="translate(373.84 116.85) rotate(115.62)" fill="#263238"></rect>
                <rect x="149.26" y="173.29" width="0.57" height="8.11" transform="translate(374.11 119.17) rotate(115.62)" fill="#263238"></rect>
                <path d="M153.78,160.56l-1.51,1.73,2.24-4.69-.39,2.27,2.68,1.29c.88-1.34,2.56-1.23,5,0l-2.24,4.68c-2.46-1.18-3.6-2.42-3.11-3.94Z" fill="#263238"></path>
                <polygon points="158.61 158.23 159.2 157.01 155.62 155.29 157.12 152.16 160.7 153.88 161.29 152.65 163.68 157.23 158.61 158.23" fill="#263238"></polygon>
                <polygon points="139.2 178.43 143.77 180.62 173.27 140.76 160.26 134.52 139.2 178.43" fillOpacity="0.1"></polygon>
                <polygon points="178.98 213.44 200.01 169.59 197.05 152.16 176.03 196.01 178.98 213.44" style={{ fill: Props.colorTheme.activeColor }}></polygon>
                <polygon points="178.98 213.44 200.01 169.59 197.05 152.16 176.03 196.01 178.98 213.44" fillOpacity="0.1"></polygon>
                <path d="M103.2,117.78c-3.59,18-5.48,51.44-2.44,57.26,2.69,5.15,22.9,15,32.81,17.79,3.71,1,17.47-22.88,13.48-23.92-4.78-1.25-25.4-10.53-25.92-11.72s-3.44-25.39-7.23-39.47C110.46,105,104.56,111,103.2,117.78Z" fill="#ffc4c0"></path>
                <path d="M129.81,191.29c8.13,6.87,17.83,13.12,28.93,14a5.49,5.49,0,0,0,3.51-.83c5,.47,6.67-1.74,6.67-1.74,4.76-1,6.19-3.48,6.19-3.48s5.84.48,6.72-3c1-3.9-7.4-3.81-12-6.58-10.64-6.35-24-18.36-26.87-20.5Z" fill="#ffc4c0"></path>
                <path d="M154,190.79a80.78,80.78,0,0,0,9.63,5A79.87,79.87,0,0,0,175,199.07a.1.1,0,0,1,0,.2,42.19,42.19,0,0,1-21.13-8.34C153.74,190.82,153.8,190.7,154,190.79Z" fill="#263238"></path>
                <path d="M149.19,196.65c3.18,1.59,8.79,4.34,19.75,5.9,0,0,0,.07,0,.07a37.82,37.82,0,0,1-19.82-5.82C148.93,196.69,149,196.54,149.19,196.65Z" fill="#263238"></path>
                <path d="M146.69,199.85c5.51,2.49,9.3,3.37,15.54,4.53,0,0,0,.07,0,.07-7.23-.58-9.78-1.61-15.64-4.42C146.38,199.93,146.47,199.75,146.69,199.85Z" fill="#263238"></path>
                <path d="M156,183.21c-1.17-1.28,1.27-3.28,4.73-3.42,3.12-.13,14.78,4,16-.24s-15.23-10.42-20.36-10.87-13.6.61-13.6.61C143.79,170.07,150.23,179.49,156,183.21Z" fill="#ffc4c0"></path>
                <path d="M129.63,194.77l11.05-31.07L122.91,155c-2-10.94-6.06-32.83-8.6-40.19-3-8.63-9.16-7.13-11.52,1.86-5.41,20.58-6.12,55-3.74,59.8C102.46,183.26,129.63,194.77,129.63,194.77Z" style={{ fill: Props.colorTheme.activeColor }}></path>
                <path d="M125.26,188.77c.35-2.17,3.24-10.75,4-12.88s4-10.57,4.8-12.76c0-.12.22-.09.18,0-.73,2.18-3.35,10.89-4.07,13s-3.71,10.67-4.78,12.62A.06.06,0,0,1,125.26,188.77Z" fill="#263238"></path>
                <path d="M118.19,189.46q-4.11-2.06-8.09-4.33a72.83,72.83,0,0,1-7.72-5,14.41,14.41,0,0,1-3.33-3.24,13.12,13.12,0,0,1-1.22-4.49,67.39,67.39,0,0,1-.42-9.2c0-6.12.5-12.23,1-18.32s1.31-12.16,2.22-18.2c-.58,6.08-1.12,12.17-1.58,18.26s-.84,12.18-1,18.27a81.5,81.5,0,0,0,.3,9.12,12.92,12.92,0,0,0,1,4.32,14,14,0,0,0,3.17,3.16,79.19,79.19,0,0,0,7.59,5.1Q114.14,187.32,118.19,189.46Z" fill="#263238"></path>
                <path d="M100,131.9a8.28,8.28,0,0,1-.3-1.71,5,5,0,0,1,.08-1.73c0,.29,0,.57.08.86l.08.85A14.29,14.29,0,0,1,100,131.9Z" fill="#263238"></path>
                <path d="M118.19,131.53c1,3.52,1.6,7.12,2.34,10.69s1.35,7.16,2.16,10.7c.1.44.2.88.32,1.3l.09.31c0,.08.1.23.06.18.11.06.3.19.5.29.78.43,1.6.84,2.41,1.26L131,158.7c3.25,1.65,6.51,3.28,9.74,5h0v0l-.31.83.24-.85,0,.05c-3.35-1.45-6.66-3-10-4.5-1.65-.77-3.29-1.57-4.93-2.37-.82-.41-1.63-.81-2.45-1.24a4.66,4.66,0,0,1-.67-.42,1.32,1.32,0,0,1-.23-.44l-.11-.34c-.13-.45-.24-.9-.34-1.35-.76-3.58-1.4-7.16-2-10.76l-.88-5.4C118.81,135.12,118.57,133.31,118.19,131.53Z" fill="#263238"></path>
                <path d="M119.19,132.44a10.16,10.16,0,0,1,.1,1.74,5.59,5.59,0,0,1-.29,1.72c0-.29,0-.58,0-.86l0-.87A14.61,14.61,0,0,1,119.19,132.44Z" fill="#263238"></path>
                <path d="M181.2,100.06c4.15.27,10.41-1.38,13.27-.21a3.54,3.54,0,0,1,2.31,3.54s2.82-1.93,5.24-.44c1.25.77,1.11,3.43,1.11,3.43a4.25,4.25,0,0,1,4.4,0c1.65,1.17,1.27,3.66,1.27,3.66s2.89-.35,4.19,1.37c2.63,3.5-5.79,18.62-12.67,17.86-3.2-.36-.94-3.94-.94-3.94s-4.5,4.36-7.66,2.71.05-6.14.05-6.14-4.39,4.19-7.49,2.15S185,118,185,118s-4.16,2.1-6.18,0c-3.33-3.49,5.28-7,6.35-10.8l-4.46-4.89Z" fill="#ffc4c0"></path>
                <path d="M187.65,99.69c-3.9-.6-5.66-6.84-9.32-8-4.94-1.5-3.38,5.87,2.41,10.54l5.13,2.84Z" fill="#ffc4c0"></path>
                <path d="M208.8,110.45a0,0,0,0,1,.09,0,30.12,30.12,0,0,1-9.61,15.07c-.15.12-.42-.08-.28-.24A61.35,61.35,0,0,0,208.8,110.45Z" fill="#263238"></path>
                <path d="M203.21,106.33c0-.06.13,0,.11,0a28.11,28.11,0,0,1-11.51,15.78c-.2.13-.5,0-.28-.12C195.21,119.34,200.91,111.43,203.21,106.33Z" fill="#263238"></path>
                <path d="M184.52,118.12c5.44-3.68,9.14-8.74,12.2-14.48,0-.08.14,0,.13.06-.73,3.21-3.07,6.67-5.11,9.21a17.2,17.2,0,0,1-7.09,5.4C184.54,118.36,184.4,118.2,184.52,118.12Z" fill="#263238"></path>
                <path d="M121.53,115.09c-3.81,0-9.42-7.58-10.83-12.62-.1-.34,1.12-3.47,2.42-7.33.78-2.35,1.59-5,2.16-7.44L129.82,95a40.5,40.5,0,0,0-3.67,9.09,6.39,6.39,0,0,0-.12,1c0,.05,0,.11,0,.17C125.88,107.29,125.21,115.07,121.53,115.09Z" fill="#ffc4c0"></path>
                <path d="M126,105.13a1.16,1.16,0,0,1,0,.17,10.17,10.17,0,0,1-1.6-.28c-8.87-2.26-8.45-13.66-8.15-16.82L119.9,90l9.92,5a39.67,39.67,0,0,0-3.66,9.09A5.52,5.52,0,0,0,126,105.13Z" fill="#263238"></path>
                <path d="M117.53,71.94c-2.68,5.21-2.66,21.68.62,25.94,4.75,6.18,14,8.37,19.6,2.32,5.44-5.86,5-27.32,1.24-31.34C133.39,62.93,121.5,64.24,117.53,71.94Z" fill="#ffc4c0"></path>
                <path d="M131.35,85.13s-.09.05-.09.1c0,1.11-.12,2.38-1.12,2.75a0,0,0,0,0,0,.06C131.37,87.87,131.56,86.18,131.35,85.13Z" fill="#263238"></path>
                <path d="M130.36,84c-1.78-.14-1.93,3.43-.28,3.56S131.86,84.09,130.36,84Z" fill="#263238"></path>
                <path d="M137.64,85.19s.09.06.09.1c-.09,1.11,0,2.39,1,2.8,0,0,0,.07,0,.06C137.49,87.93,137.39,86.23,137.64,85.19Z" fill="#263238"></path>
                <path d="M138.68,84.08c1.79-.06,1.78,3.52.12,3.57S137.18,84.13,138.68,84.08Z" fill="#263238"></path>
                <path d="M129,81.61a14,14,0,0,0,1.41-.39,2.38,2.38,0,0,0,1.37-.67.75.75,0,0,0,0-.92,1.87,1.87,0,0,0-1.88-.33,2.79,2.79,0,0,0-1.67,1.07A.81.81,0,0,0,129,81.61Z" fill="#263238"></path>
                <path d="M140.19,81.13a13.51,13.51,0,0,1-1.41-.43,2.37,2.37,0,0,1-1.35-.71.75.75,0,0,1,.08-.92,1.87,1.87,0,0,1,1.88-.28A2.77,2.77,0,0,1,141,79.92.81.81,0,0,1,140.19,81.13Z" fill="#263238"></path>
                <path d="M129.48,94.93c.25.27.5.64.9.69a2.73,2.73,0,0,0,1.2-.23,0,0,0,0,1,0,.06,1.49,1.49,0,0,1-1.43.55,1.18,1.18,0,0,1-.83-1C129.35,94.91,129.44,94.89,129.48,94.93Z" fill="#263238"></path>
                <path d="M130.78,91.66a3.77,3.77,0,0,0,2.45,1.79,4.72,4.72,0,0,0,1.35.12,1.23,1.23,0,0,0,.27,0,1.05,1.05,0,0,0,.24,0,.26.26,0,0,0,.23-.23h0v-.12h0v-.1c.06-.85,0-2.15,0-2.15.31.16,1.87,1,1.86.5a56.18,56.18,0,0,0-1-11.48.1.1,0,0,0-.2,0c-.08,3.56.46,7.1.44,10.68a6.77,6.77,0,0,0-1.76-.62c-.12,0,.1,2.52,0,2.9a.16.16,0,0,1,0,0,5.25,5.25,0,0,1-3.85-1.38C130.81,91.5,130.72,91.57,130.78,91.66Z" fill="#263238"></path>
                <path d="M115.51,87.81s-2.8-13.42,1.79-19.61c4.77-6.43,16-6.48,20.84-1.21,3.36,3.71,3.3,9.42,3.3,9.42s-.8-3.58-1.87-5-1.71-1.14-2.14-.1-.56,2.67-2,2.37-4-3.78-8.19-3.33-5.86,4.58-6.32,8,.61,5.84-1.3,7.92C118.55,87.55,115.51,87.81,115.51,87.81Z" fill="#263238"></path>
                <path d="M119.39,85.69s-2.53-5.87-5.12-5.07-1.43,8.79,1.09,10.37a2.84,2.84,0,0,0,4-.72Z" fill="#ffc4c0"></path>
                <path d="M114.66,82.9a0,0,0,0,0,0,.07c1.75,1.17,2.39,3.16,2.72,5.15a1.57,1.57,0,0,0-2.21-.9.06.06,0,0,0,0,.11,1.76,1.76,0,0,1,1.76,1,9.09,9.09,0,0,1,.59,1.71c0,.19.38.16.35,0v0C118.42,87.5,117.23,83.69,114.66,82.9Z" fill="#263238"></path>
                <path d="M126.35,104.29,122.14,113,111.6,99.52,108,104.43s10.09,18.65,10.21,17.84l4.23-7.48,5.14,5.7.34-13.49Z" fill="#ebebeb"></path>
                <path d="M128.11,113.83c0-2.23,0-4.47-.1-6.7,0-.08-.13-.12-.16,0-.58,2.12-.46,10.64-.59,12.68l0,0c-.21-.24-4.78-5-4.83-5-.89,1.18-4,6.62-4.21,7.12-1.6-3-7.75-13.75-9.68-16.58a.08.08,0,0,0-.14.07c1.38,3.11,9.51,17.63,9.65,17.6a85,85,0,0,0,4.42-7.91c.76,1,5.34,6.39,5.38,6.06A68.79,68.79,0,0,0,128.11,113.83Z" fill="#263238"></path>

            </svg>
        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}

export default connect(mapStateToProps)(ServersSVG);
