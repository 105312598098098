import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { getTheme } from '../../helper';
import { motion } from "framer-motion";

const CustomIcon = (Props) => {

  const { animated } = Props
  let animation = animated ? Props.animation : {}


  return (
    <motion.div {...animation}>
      <FontAwesomeIcon icon={Props.icon} color={Props.theme.mainColor} style={Props.style} />
    </motion.div >
  );
};

const mapStateToProps = (state) => {
  return {
    isDarkTheme: state.theme.isDarkTheme,
    theme: getTheme(state.theme.isDarkTheme),
  }
}


export default connect(mapStateToProps)(CustomIcon);
