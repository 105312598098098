import React from 'react';
import { NavLink } from 'react-router-dom';
import { getColorThemeByName, getTheme } from "../../helper";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import CustomIcon from '../CustomIcon/CustomIcon';

const LinkNav = (Props) => {

    const { elementData, index } = Props

    let style = {
        borderDiv: {
            borderBottom: `1px solid ${Props.theme.borderColor}`
        },
        link: {
            fontSize: "1.2rem",
            fontWeight: "600"
        },
    }

    let activeColor = Props.colorTheme.activeColor;
    let noneActiveColor = Props.theme.normalTextColor;

    return (
        <li key={index} className='col container mt-4'>
            <div style={style.borderDiv}>
                <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1 }}>
                    <NavLink className='text-decoration-none' to={elementData.href}
                        style={({ isActive }) => {
                            return {
                                ...style.link,
                                color: isActive ? activeColor : noneActiveColor
                            }
                        }}
                    >
                        {({ isActive }) => (
                            <div className='row justify-content-center'>
                                <div className='col-3'>
                                    <CustomIcon style={{ color: isActive ? activeColor : noneActiveColor }}
                                        icon={elementData.icon} />
                                </div>
                                <div className='col-3 text-start'>
                                    {elementData.name}
                                </div>
                            </div>
                        )}
                    </NavLink>
                </motion.div>
            </div>
        </li>
    );
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        nameColorTheme: state.theme.nameColorTheme,
        colorTheme: getColorThemeByName(state.theme.nameColorTheme)
    }
}

export default connect(mapStateToProps)(LinkNav);
