import React from 'react';
import {getTheme} from "../../helper";
import { connect } from "react-redux";


const GroupBox = (Props) => {
    const {isBordered = true} = Props

    let style = {
        ...Props.style,
        border: isBordered ? `1px solid ${Props.theme.borderColor}` : "none",
        backgroundColor: Props.theme.darkBackColor,
        borderRadius:"10px"
    }

    return (
        <div className="d-flex align-items-center text-center px-2" style={style}>
            {Props.children}
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme)
    }
}

export default connect(mapStateToProps)(GroupBox);
