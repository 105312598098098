import React from 'react';
import { getTheme } from "../../helper";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import ThemeButtonChanger from "../ThemeButtonChanger";
import { faPalette } from "@fortawesome/free-solid-svg-icons";
import GroupBox from "../GroupBox/GroupBox";
import themes from "../../constant/themes";


const ThemeControlPanel = (Props) => {
    let style = {
        darkButton: {
            height: "40px",
            width: "40px",
        },

        colorButton: {
            height: "30px",
            width: "30px",
            marginLeft: "2px",
            marginRight: "2px"
        }

    }

    const variants = {
        close: {
            x: -170
        },
        open: {
            x: 0
        }
    };

    return (
        <motion.div variants={variants} initial={{ x: 170 }} animate={Props.isColorPanleOpen ? "open" : "close"}
            className="d-flex position-relative mb-3"
            style={{ right: -10, top: 0, width: "fit-content" }}>
            <GroupBox>
                <div>
                    <div>
                        <p>Theme Colors</p>
                    </div>
                    <div>
                        {
                            Object.values(themes.appColorsTheme).map((color, index,) => {
                                return (
                                    <ThemeButtonChanger
                                        key={index}
                                        style={style.colorButton}
                                        app_color_theme={color}
                                    />
                                )
                            })
                        }
                    </div>
                </div>
            </GroupBox>
            <div className="ms-3">
                <div>
                    <ThemeButtonChanger
                        icon={Props.theme.icon}
                        style={style.darkButton}
                        animated="true"
                    />
                </div>
                <div className="mt-3">
                    <ThemeButtonChanger
                        icon={faPalette}
                        style={style.darkButton}
                        forColorPanale
                    />
                </div>
            </div>

        </motion.div>
    )
};


const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        isColorPanleOpen: state.theme.isColorPanleOpen
    }
}

export default connect(mapStateToProps)(ThemeControlPanel);
