import React from 'react';
import Logo from "../Logo";
import SideBarNavElement from "../SideBarNavElement/SideBarNavElement";
import { getTheme } from "../../helper";
import { connect } from "react-redux";
import ThemeControlPanel from '../ThemeControlPanel/ThemeControlPanel';
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import CustomIcon from '../CustomIcon/CustomIcon';
import { toggleNavbarOpen } from '../../reduxapp/actions/actions';
import { motion } from "framer-motion";
import { NavLink } from 'react-router-dom';

const SideBar = (Props) => {

    let style = {
        wrapper: {
            backgroundColor: Props.theme.lightBackColor,
        },
        logoDiv: {},
        listDiv: {
            marginTop: "-5rem"
        }
    }

    return (
        <>
            {/* NavBar for screen */}
            <div className='d-none d-lg-flex flex-column col-lg-3 vh-100 sticky-top' style={style.wrapper}>
                <div className='mb-auto mt-5 me-lg-auto ms-lg-auto' style={style.logoDiv}>
                    <NavLink className='text-decoration-none' to='/'>
                        <Logo />
                    </NavLink>
                </div>

                <div className='mb-lg-auto' style={style.listDiv}>
                    <SideBarNavElement isMobile={true} />
                </div>

                <ThemeControlPanel />

            </div>

            {/* NavBar for mobile */}

            <div className='d-lg-none flex-column col-12' style={style.wrapper}>
                <div className='navbar'>
                    <div className='mt-5 me-auto ms-auto' style={style.logoDiv}>
                        <NavLink className='text-decoration-none' to='/'>
                            <Logo />
                        </NavLink>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <div className='mb-5 mt-5 text-center container'>
                        <SideBarNavElement isMobile={false} />
                    </div>

                    <div className='mt-5'>
                        <ThemeControlPanel />
                    </div>
                </div>
                <div className='mt-2 text-center'>
                    <motion.h1 animate={{ rotate: Props.isNavbarOpen ? 180 : 0 }}
                        className="navbar-toggler" type="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation" onClick={() => { Props.toggleNavbarOpen() }}
                    >

                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}
                            transition={{ repeat: Infinity, duration: 1, delay: 0.5, repeatType: "reverse" }}>
                            <CustomIcon icon={faArrowDown} />
                        </motion.div>
                    </motion.h1>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNavbarOpen: () => dispatch(toggleNavbarOpen()),
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme),
        isNavbarOpen: state.navbar.isNavbarOpen
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
