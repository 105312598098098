import { combineReducers } from "redux";
import DatabaseReducer from "./databaseReducer";
import NavBarReducer from "./navbarReducer";
import themeReducer from "./themeReducer";

const appReducers = combineReducers({
    theme: themeReducer,
    navbar: NavBarReducer,
    database: DatabaseReducer
})

export default appReducers;