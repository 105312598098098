import React from 'react';
import { connect } from 'react-redux';
import { getColorThemeByName, getTheme } from '../../helper';

const InfoRow = (Props) => {
  const { info1, value1, info2, value2 } = Props
  return (
    <div className='row mt-4'>
      <div style={{ borderBottom: `1px solid ${Props.theme.borderColor}` }} className='col-5 me-5'>
        <h5 className='d-inline-block'>{info1}: &nbsp;</h5>
        <h5 style={{ fontWeight: "200" }} className='d-inline-block text-break'>{value1}</h5>
      </div>
      <div style={{ borderBottom: `1px solid ${Props.theme.borderColor}` }} className='col-5'>
        <h5 className='d-inline-block'>{info2}: &nbsp;</h5>
        <h5 style={{ fontWeight: "200" }} className='d-inline-block text-break'>{value2}</h5>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isDarkTheme: state.theme.isDarkTheme,
    theme: getTheme(state.theme.isDarkTheme),
    database: state.database,
    nameColorTheme: state.theme.nameColorTheme,
    colorTheme: getColorThemeByName(state.theme.nameColorTheme)
  }
}

export default connect(mapStateToProps)(InfoRow);
