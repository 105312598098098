import { CHANGE_COLOR_THEME, TOGGLE_COLOR_PANALE, TOGGLE_DARK_THEME, TOGGLE_NAVBAR_OPEN } from "./types";

export const toggleDarkTheme = () => {
    return {
        type: TOGGLE_DARK_THEME,
    }
}

export const changeColorTheme = (nameColorTheme) => {
    return {
        type: CHANGE_COLOR_THEME,
        nameColorTheme: nameColorTheme
    }
}

export const toggleColorPanale = () => {
    return {
        type: TOGGLE_COLOR_PANALE,
    }
}

export const toggleNavbarOpen = () => {
    return {
        type: TOGGLE_NAVBAR_OPEN,
    }
}