import mochupLogoDark from "./assets/images/website/mochup-logo-dark.png";
import mochupLogoLight from "./assets/images/website/mochup-logo-ligth.png";
import mochupFlyerDark from "./assets/images/website/mochup-flyer-dark.png";
import mochupFlyerLight from "./assets/images/website/mochup-flyer-ligth.png";
import mochupBannerDark from "./assets/images/website/mochup-banner-dark.png";
import mochupBannerLight from "./assets/images/website/mochup-banner-ligth.png";
import mochupCustomDark from "./assets/images/website/mockup-custom-dark.png";
import mochupCustomLight from "./assets/images/website/mockup-custom-light.png";
import businessToMoblieLight from "./assets/images/website/businessToMoblieLight.jpg";
import businessToMoblieDark from "./assets/images/website/businessToMoblieDark.jpg";
import editDark from "./assets/images/website/editDark.jpg";
import editLight from "./assets/images/website/editLight.jpg";
import featursLight from "./assets/images/website/featursLight.jpg";
import featursDark from "./assets/images/website/featursDark.jpg";
import changeThameWebDark from "./assets/images/website/changeThameWebDark.jpg";
import changeThameWebLight from "./assets/images/website/changeThameWebLight.jpg";
import CMSLight from "./assets/images/website/CMS_light.png";
import CMSDark from "./assets/images/website/CMS_Dark.png";

import ArzttagD1 from "./assets/images/website/Arzttag/D1.png"
import ArzttagM1 from "./assets/images/website/Arzttag/M2.png"
import ArzttagLogo from "./assets/images/website/Arzttag/Logo 1.png"

import BrueckenLogo1 from "./assets/images/website/Bruecken/Logo4_Verkant.png"
import BrueckenLogo2 from "./assets/images/website/Bruecken/Logo.png"
import BrueckenLogo3 from "./assets/images/website/Bruecken/Logo2.png"
import BrueckenLogo4 from "./assets/images/website/Bruecken/Logo3_Big.png"
import BrueckenLogo5 from "./assets/images/website/Bruecken/Logo4_Islamic.png"
import BrueckenLogo6 from "./assets/images/website/Bruecken/Logo5.png"
import BrueckenLogo7 from "./assets/images/website/Bruecken/Logo4.png"
import BrueckenBrief from "./assets/images/website/Bruecken/Brücken Verein - Vertikal-Brief - 8-2022.jpg"

import ChickenTimeLogo from "./assets/images/website/Chicken_Time/Logo.png"
import ChickenTimeLogo_entwicklung from "./assets/images/website/Chicken_Time/Logo_Entwicklung.png"
import ChickenTimeMenu1 from "./assets/images/website/Chicken_Time/Menu1.png"
import ChickenTimeMenu2 from "./assets/images/website/Chicken_Time/Menu2.png"
import ChickenTimeMenu3 from "./assets/images/website/Chicken_Time/Menu3.png"
import ChickenTimeMenu4 from "./assets/images/website/Chicken_Time/Menu4.png"
import ChickenTimeAngebot1 from "./assets/images/website/Chicken_Time/Angebot1.png"
import ChickenTimeAngebot2 from "./assets/images/website/Chicken_Time/Angebot2.png"
import ChickenTimePoster from "./assets/images/website/Chicken_Time/Poster_Without_Seels - Nummer 2.png"
import ChickenTimePoster2 from "./assets/images/website/Chicken_Time/Poster_Without_Seels.png"

import NapoliLogo from "./assets/images/website/Napoli/Logo.png"
import NapoliIMenu1 from "./assets/images/website/Napoli/1_Front.png"
import NapoliIMenu2 from "./assets/images/website/Napoli/1_Back.png"
import NapoliOMenu1 from "./assets/images/website/Napoli/1.png"
import NapoliOMenu2 from "./assets/images/website/Napoli/2.png"
import NapoliMMenu1 from "./assets/images/website/Napoli/Moaganat2_OUT.png"
import NapoliMMenu2 from "./assets/images/website/Napoli/Moaganat2_IN.png"

import PRMPlakat from "./assets/images/website/PRM/ProProPlakat.png"
import PRM1 from "./assets/images/website/PRM/Bild1.png"
import PRM2 from "./assets/images/website/PRM/Login.JPG"
import PRM3 from "./assets/images/website/PRM/AHome.JPG"
import PRM4 from "./assets/images/website/PRM/AAuftrag.JPG"
import PRM5 from "./assets/images/website/PRM/AWachpersonal.JPG"
import PRM6 from "./assets/images/website/PRM/AAuswertung.JPG"
import PRM7 from "./assets/images/website/PRM/W1.JPG"
import PRM8 from "./assets/images/website/PRM/W2.JPG"
import PRM9 from "./assets/images/website/PRM/W3.JPG"
import PRM10 from "./assets/images/website/PRM/W4.JPG"

import EV3Seit from "./assets/images/website/EV3Nao/Webseit.png"
import EV3Concept from "./assets/images/website/EV3Nao/k_konzept.png"
import NaoConcept from "./assets/images/website/EV3Nao/Konzept.png"

import SyriansweetsHome from "./assets/images/website/Syriansweets.nl/Lekker Zoet - Vers Patisserie - Syriansweets – lekker Zoet & Vers Pat_ - syriansweets.nl.png"
import SyriansweetsProducts from "./assets/images/website/Syriansweets.nl/Producten – lekker Zoet - Vers Patisserie - Syriansweets - syriansweets.nl.png"

import DHTMobileUI from "./assets/images/website/DTH/Mobile_UI.png"

import SmartHeroUI1 from "./assets/images/website/SmartHero/1.png"
import SmartHeroUI2 from "./assets/images/website/SmartHero/2.png"
import SmartHeroUI3 from "./assets/images/website/SmartHero/3.png"

import MeinMoscheeUI1 from "./assets/images/website/MeineMoschee/1.jpeg"
import MeinMoscheeUI2 from "./assets/images/website/MeineMoschee/2.jpeg"
import MeinMoscheeUI3 from "./assets/images/website/MeineMoschee/3.jpeg"
import MeinMoscheeUI4 from "./assets/images/website/MeineMoschee/4.jpeg"
import MeinMoscheeUI5 from "./assets/images/website/MeineMoschee/5.jpeg"
import MeinMoscheeUI6 from "./assets/images/website/MeineMoschee/6.jpeg"


const today = new Date();
const birthDate = new Date("04/17/1996");
const age = today.getFullYear() - birthDate.getFullYear()

const database = {
    personal: {
        firstName: "Mohammad Ghaith",
        lastName: "Albaba",
        birthday: birthDate.toLocaleDateString("de-DE"),
        age: age
    },
    contact: {
        website: "its-ghaith.com",
        email: "Mohammad.g.albaba@gmail.com",
        phone: "+49 177 1569331"
    },
    career: {
        name: [
            "Software Development",
            "Mobile Development",
            "Web Development",
            "Design",
        ],

        careers: [
            {
                date: "Feb 2021 - Oct 2021",
                title: "EDEKS-Markt Minden-Hannover GmbH",
                desc: "Student assistant"
            },
            {
                date: "Dec 2014 – Sept 2015",
                title: "Fajr Iqraa Schule, Bursa, Turkey ",
                desc: "Werbegestalter"
            },
            {
                date: "2011 – 2013",
                title: "Computer engineering company, Damascus",
                desc: "Nebenjob im Bereich Computerwartung"
            },
        ],

        studyProjects: [
            {
                date: "14 Sept 2020 - 15 Jan 2021",
                title: "Institute for Medicine & Technology e.V. (IMT) at the Anhalt University of Applied Sciences (affiliated institute)",
                desc: "Internship in the field of robotics Topic of the work: Speech recognition with the Nao robot, and control and communication with EV3"
            },
            {
                date: "Nov 2019 – Mar 2020",
                title: "Gisa GmbH, Halle (Saale)",
                desc: 'System for detecting illegal parking using the measurement data received from the "LoRaWAN" sensors'
            },
            {
                date: "Apr 2019 – Jun 2019",
                title: "",
                desc: "Web application for selling products"
            },
        ],


        motto: `Hard work and research ability are two mottos in my career`,
        description: `I'm a web Designer and Developer with extensive experience for over 4 years.
        I have worked on many projects with different technologies and languages.
        My main focus is on the full stack development.`,
        longDescription: `Hardworking Student, willing to employ my skills and passion to further the mission of the company. 
        Bringing forth a positive attiude and the willingness and motivation to learn new. I am familiar with various languages, software, and database management, in addition, I have a significant ability to manage servers. I also care about DevOps techniques and full stack development.`,
        backendSkill: 92,
        frontendSkill: 84,
    },
    education: [
        {
            date: "Oct 2018 – today",
            title: "Bachelor at Anhalt University of Applied Sciences",
            desc: "Applied Computer Science - Digital Media and Game Development"
        },
        {
            date: "Apr 2017 – Jun 2018",
            title: "Studienkolleg: Technique course at Anhalt University of Applied Sciences",
            desc: "grades: 2,9"
        },
        {
            date: "Oct 2013 – Jun 2014",
            title: "Faculty of Applied Sciences, specialization Computer Engineering at University of Damascus",
            desc: "(two semesters) without a degree"
        },
        {
            date: "Oct 2010 – Jun 2013",
            title: "High school of industrial and vocational education, computer engineering profession",
            desc: "Abitur with an average grade of 91.92%"
        },
    ],
    programmingSkills: [
        [
            {
                name: "PHP",
                level: "very good",
                icon: "devicon-php-plain",
            },
            {
                name: "Javascript",
                level: "very good",
                icon: "devicon-javascript-plain",
            },
            {
                name: "Java",
                level: "very good",
                icon: "devicon-java-plain",
            },
            {
                name: "Python",
                level: "very good",
                icon: "devicon-python-plain",
            },
        ],
        [
            {
                name: "Dart",
                level: "very good",
                icon: "devicon-dart-plain",
            },
            {
                name: "C#",
                level: "good",
                icon: "devicon-csharp-plain",
            },
            {
                name: "C++",
                level: "Basic knowledge",
                icon: "devicon-cplusplus-plain",
            },
            {
                name: "Matlab",
                level: "Basic knowledge",
                icon: "devicon-matlab-plain",
            }
        ]
    ],
    frameworks: [
        [
            {
                name: "Laravel",
                icon: "devicon-laravel-plain",
            },

            {
                name: "Codeigniter",
                icon: "devicon-codeigniter-plain",
            },
            {
                name: "React",
                icon: "devicon-react-original",
            },
            {
                name: "Spring",
                icon: "devicon-spring-plain",
            },

        ],
        [
            {
                name: "flask",
                icon: "devicon-flask-original",
            },
            {
                name: "Flutter",
                icon: "devicon-flutter-plain",
            },
            {
                name: "Ionic",
                icon: "devicon-ionic-original",
            },
            {
                name: "Bootstrap",
                icon: "devicon-bootstrap-plain",
            },

        ]
    ],
    technologien: [
        [
            {
                name: "Docker",
                icon: "devicon-docker-plain",
            }
        ],
        [
            {
                name: "Apache Kafka",
                icon: "devicon-apachekafka-original",
            }
        ]
    ],
    designSkills: [
        [
            {
                name: "Photoshop",
                icon: "devicon-photoshop-plain",
                level: "very good",
            },
            {
                name: "After Effects",
                icon: "devicon-aftereffects-plain",
                level: "good",
            }
        ],
        [
            {
                name: "Illustrator",
                icon: "devicon-illustrator-plain",
                level: "very good",
            },
            {
                name: "Premiere",
                icon: "devicon-premierepro-plain",
                level: "good",
            }
        ]
    ],
    services: {
        motto: `Take your project or bussiniess to the next level`,
        longDescription: `Do you want to start a new bussiniess? Or do have already a seccessul bussiniess but it's not digital?
        Or do you want to expand your bussiniess? I'm here to help you. I can help you with the following services:`,
        servicesList: {
            design: [
                {
                    categorie: "Design",
                    name: "Professional Logo",
                    imgDark: mochupLogoDark,
                    imgLight: mochupLogoLight,
                    description: "Make your existing logo more professional for your business or project. I can also create a logo for you if you want to start a new business. And all this in line with the visual identity of your project.",
                },
                {
                    categorie: "Design",
                    name: "Create a Flyer",
                    imgDark: mochupFlyerDark,
                    imgLight: mochupFlyerLight,
                    description: "The flyer is a great way to show your business to potential customers. Do you have a business e.g. a restaurant or a company? I can help you with creact a flyer. The design of the flyer is based on the visual identity of your business, and it will be completely unique.",
                },
                {
                    categorie: "Design",
                    name: "Let the banner represent your business",
                    imgDark: mochupBannerDark,
                    imgLight: mochupBannerLight,
                    description: "With a Design of banners and posters you can represent your business for the public in events and exhibitions. To do that you can connect with me.",
                },
                {
                    categorie: "Design",
                    name: "Custom ",
                    imgDark: mochupCustomDark,
                    imgLight: mochupCustomLight,
                    description: "If you have an idea for a new design, or you want to design anthoer press material, which not in the list is. You can easily contact me.",
                },
            ],
            web: [
                {
                    categorie: "web",
                    name: "Change the theme of your website",
                    imgDark: changeThameWebDark,
                    imgLight: changeThameWebLight,
                    description: "The new trends in the web are changing the way we interact with the world. Do you want to change the theme of your website? I can help you with that.",
                },
                {
                    categorie: "web",
                    name: "Create a new web application for your Business",
                    imgDark: mochupFlyerDark,
                    imgLight: mochupFlyerLight,
                    description: "Because of the 4th regulation of digitalization, you should take your business using your website in the next level. To do that, i can help you.",
                },
                {
                    categorie: "web",
                    name: "Develop a website for your Business using CMS",
                    imgDark: CMSDark,
                    imgLight: CMSLight,
                    description: "If you want to develop a sameple website about you or your business, it's enough, that you create a website using Wordpress, which is very easy to mangage and update the date of your website.",
                },
            ],
            mobile: [
                {
                    categorie: "mobile",
                    name: "Create the first mobile app for your Bussniss",
                    imgDark: businessToMoblieDark,
                    imgLight: businessToMoblieLight,
                    description: "In addition to having a website for your business, the importance of mobile applications should not be underestimated. Because of it, you can reach new and active customers.",
                },
                {
                    categorie: "mobile",
                    name: "Edit the Design of your extsist mobile app",
                    imgDark: editDark,
                    imgLight: editLight,
                    description: "Your business is primarily based on mobile application customers, and you want to change the appearance of the application to make your customers aware of your interest in them in line with the user experience. All you have to do is contact me",
                },
                {
                    categorie: "mobile",
                    name: "Add new Featurs to your moblie app",
                    imgDark: featursDark,
                    imgLight: featursLight,
                    description: "You have a new idea and you want to add it to the mobile application of your business, you do not have to just take care of developing the idea and I will turn it from a thought into a digital reality.",
                },
            ],
        }
    },
    portfolio: {
        motto: `Take your project or bussiniess to the next level`,
        longDescription: `Do you want to start a new bussiniess? Or do have already a seccessul bussiniess but it's not digital?
        Or do you want to expand your bussiniess? I'm here to help you. I can help you with the following services:`,
        types: {
            design: [
                {
                    categorie: "Design",
                    name: "Logo Design For Webseit Arzttag",
                    images: [
                        ArzttagLogo,
                        ArzttagD1,
                        ArzttagM1,
                    ],
                    description: "The website owner is weary of the outdated designs on the site. Consequently, he has decided to completely revamp the entire site, infusing it with a fresh aesthetic. Drawing inspiration from fundamental design principles for creating an appealing logo, a design was crafted that encapsulates the essence of the site while embracing a contemporary aesthetic.",
                    links: ["https://arzttag.com/"]
                },

                {
                    categorie: "Design",
                    name: "Brücken e.V. in Dessau",
                    images: [
                        BrueckenLogo1,
                        BrueckenBrief,
                        BrueckenLogo2,
                        BrueckenLogo3,
                        BrueckenLogo4,
                        BrueckenLogo5,
                        BrueckenLogo6,
                        BrueckenLogo7,
                    ],
                    description: "Developing a visual identity for an association in Dessau that contributes to integrating the children of the Syrian community into German society and consolidating Syrian culture. The journey begins with developing the logo and then disseminating this identity to include all official files and messages between the association and government departments. Where I am, the first logo is the one that was approved and adopted.",
                    links: ["https://www.facebook.com/Brueckendessau/"]
                },

                {
                    categorie: "Design",
                    name: "Chicken Time Restaurant",
                    images: [
                        ChickenTimeLogo,
                        ChickenTimeLogo_entwicklung,
                        ChickenTimeMenu1,
                        ChickenTimeMenu2,
                        ChickenTimeMenu3,
                        ChickenTimeMenu4,
                        ChickenTimeAngebot1,
                        ChickenTimeAngebot2,
                        ChickenTimePoster,
                        ChickenTimePoster2,
                    ],
                    description: "The restaurant owner faced a problem in preparing pictures for fast food before opening the restaurant, especially since the restaurant’s meals could not be prepared before opening due to the lack of all equipment. Therefore, we collected and edited pictures of the restaurant’s meals, taking into account the copyright of the pictures. In addition, we prepared and arranged all the menus as pictures to be displayed on screens for the restaurant’s customers.",
                    links: ["https://its-chickentime.de/", "https://maps.app.goo.gl/aguE6xwSAgNyRXZs5"]
                },
                {
                    categorie: "Design",
                    name: "Napoli Pizzeria",
                    images: [
                        NapoliLogo,
                        NapoliIMenu1,
                        NapoliIMenu2,
                        NapoliOMenu1,
                        NapoliOMenu2,
                        NapoliMMenu1,
                        NapoliMMenu2,
                    ],
                    description: "To establish a restaurant with a simple yet elegant ambiance, the significance of uncomplicated and refined design cannot be overstated. This is particularly crucial when considering a restaurant focused on Italian cuisine, where simplicity and elegance are key elements.",
                    links: ["https://maps.app.goo.gl/Q66avHp6M6AaL1sc8"]
                },

            ],

            web: [
                {
                    categorie: "web",
                    name: "Parking space management",
                    images: [
                        PRM1,
                        PRMPlakat,
                        PRM2,
                        PRM3,
                        PRM4,
                        PRM5,
                        PRM6,
                        PRM7,
                        PRM8,
                        PRM9,
                        PRM10,
                    ],
                    description: "Creation of a multi-tenant system for the detection, documentation and archiving of illegal parking via sensors and the management of security guards. Sorted for many different companies.",
                    links: ["https://www.youtube.com/watch?v=MTKUti9paXo"]
                },
                {
                    categorie: "web",
                    name: "Chatbot for Germany's sights",
                    images: [
                        "https://github.com/its-ghaith/webserver-sw-ki/raw/main/img/1.png",
                        "https://github.com/its-ghaith/webserver-sw-ki/raw/main/img/2.png",
                    ],
                    description: "This repository is for an artificial intelligence project. The web server should think of a Sightseeing and the user should guess it. The front end is purely JavaScript, without frameworks. However, Bootstrap was used for styling in order to be able to implement the best possible mobile-friendly implementation. CSS was only used in certain cases. The web server is implemented by Flask. The library to recognize images is YOLOv5.",
                    links: ["https://github.com/its-ghaith/webserver-sw-ki/tree/main"]
                },
                {
                    categorie: "web",
                    name: "Handling the EV3 & Nao robot",
                    images: [
                        "https://cdn.sanity.io/images/7p2whiua/production/3e00192a491ff65fd9f39a77a4ddb41e18f5c737-2048x1536.jpg?w=1400&auto=format",
                        NaoConcept,
                        EV3Seit,
                        EV3Concept,
                    ],
                    description: "Internship in the field of robotics Topic of the work: Speech recognition with the Nao robot, and control and communication with EV3. Integrating artificial intelligence with the robot Nao to implement voice commands that help people with special abilities. And control the EV3 robot remotely without connecting a keyboard.",
                    links: []
                },
                {
                    categorie: "web",
                    name: "Syriansweets",
                    images: [
                        SyriansweetsHome,
                        SyriansweetsProducts,
                    ],
                    description: "The owner of a pastry bakery has this start-up project that he wants to expand and enter the world of digitalization to reach new customers outside the city of Breda in the Netherlands. The solution was to create a website on the WordPress platform with the addition of WooCommerce online store management.",
                    links: ["https://syriansweets.nl/"]
                },

            ],

            mobile: [
                {
                    categorie: "mobile",
                    name: "SmartHero",
                    images: [
                        SmartHeroUI1,
                        SmartHeroUI2,
                        SmartHeroUI3,
                    ],
                    description: "A prototype of a smart watch store. This online store was to be adopted by Anhalt University of Applied Sciences.",
                    links: ["https://github.com/its-ghaith/SmartHero"]
                },
                {
                    categorie: "mobile",
                    name: "Display humidity and temperature sensor data on mobile with an app built in flutter",
                    images: [
                        DHTMobileUI,
                        "https://github.com/its-ghaith/dht-sensor-data-on-flutter/raw/master/more_file/Technologieschema.png",
                        "https://github.com/its-ghaith/dht-sensor-data-on-flutter/raw/master/more_file/Arbeitsschritte.png",
                        "https://github.com/its-ghaith/dht-sensor-data-on-flutter/raw/master/more_file/Wemos_Schalter.jpg",
                    ],
                    description: "This project is about enabling the connection between two Wemos modules ESP8266 at different locations. The project deals with simple sensors, we try to make the concept of data transfer easier with the current programming languages and frameworks. This means that the data from an X-Sensor that is connected to X-Wemos can be transmitted without great effort and other data can be received by Y-Wemos. \nThe exchanged data is visualized by a mobile phone application(Android / IOS ).All received sensor data will be shown on an LCD connected to an X - Wemos.",
                    links: ["https://github.com/its-ghaith/dht-sensor-data-on-flutter"]
                },
                {
                    categorie: "mobile",
                    name: "MeineMoschee",
                    images: [
                        MeinMoscheeUI1,
                        MeinMoscheeUI2,
                        MeinMoscheeUI3,
                        MeinMoscheeUI4,
                        MeinMoscheeUI5,
                        MeinMoscheeUI6,
                    ],
                    description: "A program to digitize and automate everything related to the management of mosques in Europe. Such as prayer times for each city, donations and discussions via the forum.",
                    links: []
                },

            ],
        }
    }


}

export default database;