import React from 'react';
import SideBar from "../SideBar";
import { routes } from "../../constant/routers";
import { getTheme } from "../../helper"
import { connect } from "react-redux";

const App = (Props) => {
    let style = {
        backgroundColor: Props.theme.darkBackColor,
        color: Props.theme.normalTextColor,
        darkButton: {
            height: "40px",
            width: "40px",
        },

        colorButton: {
            height: "30px",
            width: "30px",
            marginLeft: "2px",
            marginRight: "2px"
        },
    }

    return (
        <div className='row' style={style}>
            <SideBar />
            <div className="col-12 col-lg-9 mt-3">
                {routes}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme)
    }
}

export default connect(mapStateToProps)(App);
