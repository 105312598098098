import React from 'react';
import { getTheme } from "../../helper";
import { motion } from "framer-motion";
import { connect } from "react-redux";
import { changeColorTheme, toggleColorPanale, toggleDarkTheme } from '../../reduxapp/actions/actions'
import CustomIcon from '../CustomIcon/CustomIcon';


const ThemeButtonChanger = (Props) => {
    const { icon = false, app_color_theme, isBordered = true, animated = false, forColorPanale = false } = Props

    let style = {
        ...Props.style,
        borderRadius: "50%",
        textAlign: "center",
        backgroundColor: app_color_theme ? app_color_theme.activeColor : Props.theme.darkBackColor,
        border: isBordered ? `1px solid ${Props.theme.borderColor}` : "none",
    }

    return (
        <motion.button whileTap={{ scale: 0.9 }} whileHover={{ scale: 1.1 }} value={"false"}
            onClick={() => {
                forColorPanale ? Props.toggleColorPanale()
                    : icon ? Props.toggleDarkTheme()
                        : Props.changeColorTheme(app_color_theme.nameColorTheme)

            }} style={style}>
            {
                icon ? <CustomIcon icon={icon} animated={animated} animation={Props.theme.animation}/> : null
            }

        </motion.button>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        toggleDarkTheme: () => dispatch(toggleDarkTheme()),
        toggleColorPanale: () => dispatch(toggleColorPanale()),
        changeColorTheme: (nameColorTheme) => dispatch(changeColorTheme(nameColorTheme))
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.theme.isDarkTheme,
        theme: getTheme(state.theme.isDarkTheme)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ThemeButtonChanger);
