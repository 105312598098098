import React from 'react';
import { connect } from "react-redux";
import { links } from "../../constant/routers";
import LinkNav from '../LinkNav/LinkNav';
import { motion } from "framer-motion";

const SideBarNavElement = (Props) => {

    const { isMobile = false } = Props;

    let style = {
        wrapper: {},
    }

    const childVariants = {
        open: {
            opacity: 1,
        },
        closed: {
            opacity: 0,
        },
    }

    return (
        <div>
            <nav>
                <ul className='list-unstyled' style={style.wrapper}>
                    {
                        links.map((element, index) =>
                            <motion.div key={index} initial={"closed"} animate={Props.isNavbarOpen || isMobile ? "open" : "closed"}
                                transition={Props.isNavbarOpen || isMobile ? { duration: 0.5 * index } : { duration: 0 }} variants={childVariants}
                            >
                                <LinkNav elementData={element} />
                            </motion.div>
                        )
                    }

                </ul>
            </nav>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        isNavbarOpen: state.navbar.isNavbarOpen
    }
}

export default connect(mapStateToProps)(SideBarNavElement);
